import React, { lazy, Suspense, useEffect } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';

import Loader from '../components/Loader';
import IACCheckForm from '../pages/ExtractionCheck';
import { ServiceRoutes } from '../config/services';
import { ServiceCodes } from '../components/Api/types';
import { GercRedirectMiddleware } from '../guards/GercRedirectMiddleware';

const HomePage = lazy(() => import('../pages/Services/HomePage'));
const UserAreaRouter = lazy(() => import('./UserAreaRouter'));
const LoginPage = lazy(() => import('../pages/LoginPage'));
const ERZSearch = lazy(() => import('../pages/ErzSearch'));
const Page404 = lazy(() => import('../pages/ErrorPage/ErrorPage'));
const PersonalDataPage = lazy(() => import('../pages/PersonalDataPage'));

const RouterContainer = (): JSX.Element => {
  const { pathname, hash, key, search } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  // @ts-ignore
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={<Loader centered />}>
            <HomePage type="all" />
          </Suspense>
        }
      />
      <Route
        path="/categories"
        element={
          <Suspense fallback={<Loader centered />}>
            <HomePage type="categories" />
          </Suspense>
        }
      />
      <Route
        path="/categories/:categorySlug"
        element={
          <Suspense fallback={<Loader centered />}>
            <HomePage type="categories" />
          </Suspense>
        }
      />
      <Route
        path="/alphabetically"
        element={
          <Suspense fallback={<Loader centered />}>
            <HomePage type="alphabetically" />
          </Suspense>
        }
      />
      <Route
        path="/alphabetically/:alphabetLetter"
        element={
          <Suspense fallback={<Loader centered />}>
            <HomePage type="alphabetically" />
          </Suspense>
        }
      />
      <Route
        path={ServiceRoutes['erz.search']}
        element={
          <Suspense fallback={<Loader centered />}>
            <ERZSearch />
          </Suspense>
        }
      />
      <Route
        path={`${ServiceRoutes[ServiceCodes.CRIMINAL_RECORD_SEARCH]}/*`}
        element={
          <Suspense fallback={<Loader centered />}>
            <IACCheckForm />
          </Suspense>
        }
      />
      <Route path="/gerc_redirect" element={<GercRedirectMiddleware />} />
      <Route
        path="/search/iac"
        element={<Navigate to={ServiceRoutes[ServiceCodes.CRIMINAL_RECORD_SEARCH]} state={{ search }} />}
      />
      <Route
        path="/search/iacl"
        element={<Navigate to={ServiceRoutes[ServiceCodes.CRIMINAL_RECORD_SEARCH]} state={{ search }} />}
      />
      <Route
        path="/userarea/user/*"
        element={
          <Suspense fallback={<Loader centered />}>
            <UserAreaRouter />
          </Suspense>
        }
      />
      <Route
        path="/login"
        element={
          <Suspense fallback={<Loader centered />}>
            <LoginPage />
          </Suspense>
        }
      />

      <Route
        path="/personal_data"
        element={
          <Suspense fallback={<Loader centered />}>
            <PersonalDataPage />
          </Suspense>
        }
      />

      <Route
        path="*"
        element={
          <Suspense fallback={<Loader centered />}>
            <Page404 errorType="404" />
          </Suspense>
        }
      />
    </Routes>
  );
};

export default RouterContainer;
