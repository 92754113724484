import { emit } from '../../eventBus';
import { getAccessToken, getRefreshToken } from '../../contexts/Auth/authTokens';
import { refreshTokens } from '../../components/Api/auth';

type RequestCallback = (headers: Headers) => Promise<Response>;

let refreshTokenPromise: Promise<unknown> = Promise.resolve();
const MAX_REQUESTS = 5;

const reauth = async (headers: Headers, cb: RequestCallback, tryiesCounter = 0): Promise<Response> => {
  if (tryiesCounter > MAX_REQUESTS) {
    emit({ action: 'logout' });
    throw new Error('too much requests');
  }

  headers.set('authorization', `Bearer ${getAccessToken()}`);

  const response = await cb(headers);

  if (response.ok) return response;

  if (![401, 403].includes(response.status)) {
    // Will be handled by next Guard
    return response;
  }

  const refreshToken = getRefreshToken();

  if (!refreshToken) {
    emit({ action: 'logout' });
    refreshTokenPromise = Promise.resolve();
    throw new Error('refresh token is not available');
  }

  try {
    await (refreshTokenPromise = refreshTokens(refreshToken));

    emit({ action: 'login' });
  } catch (e: unknown) {
    emit({ action: 'logout' });
    refreshTokenPromise = Promise.resolve();
    throw e;
  }

  return reauth(headers, cb, tryiesCounter + 1);
};

export const authMiddleware = async (cb: RequestCallback, headers?: Headers): Promise<Response> => {
  await refreshTokenPromise;

  return reauth(headers || new Headers(), cb);
};
