export const parseFormDataValue = (formDataValue: string): { [key: string]: string } => {
  if (formDataValue) {
    try {
      return JSON.parse(formDataValue);
    } catch (error) {
      return;
    }
  }
  return;
};
