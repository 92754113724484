import React from 'react';

import DefaultLink from '../../UI/Links/DefaultLink/DefaultLink';
import AccessibilityPanel from '../AccessibilityPanel/AccessibilityPanel';
import HeaderBanner from './HeaderBanner';

interface Props {
  isPanelShown: boolean;
}

const Header = ({ isPanelShown }: Props): JSX.Element => {
  return (
    <header className="header">
      <DefaultLink className="skip-link link" link="#main-menu" title="Перейти до головного меню" />
      <DefaultLink className="skip-link link" link="#main" title="Перейти до основного вмісту" />
      <DefaultLink className="skip-link link" link="#main-footer" title="Перейти до головного колонтитула" />

      <AccessibilityPanel isPanelShown={isPanelShown} />
      <HeaderBanner />
    </header>
  );
};

export default Header;
