import isJsonResponse from '../../helpers/isJsonResponse';
import { FileType } from '../../helpers/fileHelper';

export const formatMiddleware = async (response: Response, headers: Headers = null): Promise<any> => {
  if (headers && [FileType.SIGNATURE as string, FileType.PDF as string].includes(headers.get('Accept'))) {
    return response.blob();
  }

  if (isJsonResponse(response)) {
    return response.json();
  }

  return response.text();
};
