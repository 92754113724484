import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { isString } from 'lodash';

import api from '../../components/Api/api';
import { SearchFormTemplate } from '../../components/Layout/SearchForm/SearchFormTemplate';
import { IntegrationSystem, ResponseExtract, RMPSearchQueryParams } from '../../components/Api/types';
import { InputField } from '../../components/Forms/Inputs/InputField';
import { ListSearchResult } from '../../components/Forms/Results/ListSearchResult';
import { TextSearchResult } from '../../components/Forms/Results/TextSearchResult';

const currentDate = new Date();
currentDate.setFullYear(currentDate.getFullYear() - 14);

export const CheckForm: React.FC = () => {
  const { state } = useLocation();
  const searchParams = new URLSearchParams(state?.search);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<Omit<RMPSearchQueryParams, 'type'>>({
    numb: searchParams.get('number') || '',
    birthDate: searchParams.get('birth_date') || '',
  });
  const [result, setResult] = useState<ResponseExtract<IntegrationSystem.IAC> | string>(null);

  const isQR: boolean = useMemo(
    () => (searchParams.has('data') && searchParams.has('path')) || searchParams.has('requestId'),
    [searchParams],
  );

  useEffect(() => {
    if (!isQR) {
      return;
    }

    setIsLoading(true);
    api.iac
      .search({
        type: 'qr',
        data: searchParams.get('data'),
        path: searchParams.get('path'),
        requestId: searchParams.get('requestId'),
        verifyCode: searchParams.get('verify_code'),
      })
      .then(setResult)
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleChange = (name: string, value: unknown): void => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setIsLoading(true);
    setResult(null);

    api.iac
      .search({
        type: 'simple',
        ...formData,
      })
      .then(setResult)
      .finally(() => setIsLoading(false));
  };

  return (
    <SearchFormTemplate
      isLoading={isLoading}
      result={result}
      title="Перевірка витягу про наявність судимості за номером"
      canSubmit={!!formData.numb && !!formData.birthDate}
      onSubmit={onSubmit}
      headerText="Пошук за номером витягу"
      renderResult={(resultToRender): React.ReactNode =>
        isString(resultToRender) ? (
          <TextSearchResult result={resultToRender} />
        ) : (
          <ListSearchResult result={resultToRender.data.data} />
        )
      }
      qrSearch={isQR}
    >
      <InputField
        id="numb"
        name="numb"
        label="Введіть номер витягу"
        className="form-input__input"
        maxLength={14}
        type="text"
        value={formData.numb}
        change={handleChange}
        disabled={isQR}
        required
      />

      <div className="form-input form-input_margin-bottom-none required">
        <div className="form-input__label">Дата народження</div>
        <input
          type="date"
          name="birthDate"
          id="birthDate"
          value={formData.birthDate}
          className="form-input__input form-control"
          min="1923-01-01"
          max={currentDate.toISOString().split('T')[0]}
          onChange={(e): void => {
            handleChange(e.target.name, e.target.value);
          }}
          disabled={isQR}
        />
      </div>
    </SearchFormTemplate>
  );
};
