import React, { useState, useEffect } from 'react';

const formatTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
};

interface Props {
  seconds: number;
}

export const Timer: React.FC<Props> = ({ seconds }) => {
  const [time, setTime] = useState(seconds);

  useEffect(() => {
    let timer: NodeJS.Timer;

    if (time > 0) {
      timer = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [time]);

  return <div className="timer">{formatTime(time)}</div>;
};
