import React, { useContext } from 'react';

import AccessibilityContext from '../../../contexts/AccessibilityContext';
import eyeIcon from '../../../assets/images/eye-white.svg';

import './AccessibilityPanelButton.scss';

interface Props {
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const AccessibilityPanelButton: React.FC<Props> = ({ onClick }) => {
  const { turnOnAccessibilityPanel } = useContext(AccessibilityContext);

  return (
    <button
      onClick={(e): void => {
        turnOnAccessibilityPanel(e);
        onClick && onClick(e);
      }}
      className="media-title js-accessibility-btn button-right"
    >
      <img className="media-title__media" src={eyeIcon} alt="eye" width="24" height="24" />
      <span className="media-title__title">Людям із порушенням зору</span>
    </button>
  );
};
