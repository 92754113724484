import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import './HeaderWithGoBackButton.scss';

export interface HeaderWithGoBackButtonProps {
  header: React.ReactNode;
  className?: string;
  titleExpandeble?: boolean;
  hideTitleOverFlow?: boolean;
  goBackText: string;
  onGoBack: () => void;
}

export const HeaderWithGoBackButton = React.memo<HeaderWithGoBackButtonProps>(function ({
  header,
  className = '',
  titleExpandeble = false,
  hideTitleOverFlow = false,
  goBackText,
  onGoBack,
}) {
  const [isTitleExpanded, setIsTitleExpanded] = useState<boolean>(true);
  const toggleContentSize = useCallback(
    (value = !isTitleExpanded) => {
      setIsTitleExpanded(value);
    },
    [isTitleExpanded],
  );

  return (
    <div className={classNames('header-with-go-back-button', { [className]: className })}>
      <div className="header-with-go-back-button__title-container">
        <div
          className={classNames('header-with-go-back-button__title', {
            'header-with-go-back-button__title--expanded': isTitleExpanded && !hideTitleOverFlow,
          })}
        >
          {header}
        </div>

        {titleExpandeble && !hideTitleOverFlow && (
          <button
            className="header-with-go-back-button__title-toggler toggler"
            type="button"
            onClick={(): void => toggleContentSize()}
          >
            {isTitleExpanded ? 'Менше' : 'Більше'}
            <span className="header-with-go-back-button__title-toggler__icon-container">
              <img
                className={classNames({
                  expanded: isTitleExpanded,
                })}
                src="/images/arrow_right_blue.svg"
                alt="toggle informer content size"
              />
            </span>
          </button>
        )}
      </div>

      <button className="header-with-go-back-button__button" onClick={onGoBack}>
        <img className="header-with-go-back-button__button-img" src="/images/arrow_right_blue.svg" alt="arrow left" />
        <div className="header-with-go-back-button__button-text">{goBackText}</div>
      </button>
    </div>
  );
});
