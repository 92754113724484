/* eslint-disable prettier/prettier */
import React, { memo, useContext } from 'react';

import { ModalBorderTopColor, ModalConstructor } from '../ModalConstructor';
import ModalContext from '../../../contexts/Modal/ModalContext';
import './ServerErrorAlert.scss';

export const ServerErrorAlert = memo(function ServerErrorAlert() {
  const { toggleModal } = useContext(ModalContext);

  return (
    <ModalConstructor modalBorderTopColor={ModalBorderTopColor.blue}>
      <div className="server-alert">
        <div className="server-alert__img">
          <img src="/images/user-question.svg" alt="no data icon" />
        </div>
        <div className="server-alert__title">Сервіс наразі недоступний</div>
        <div className="server-alert__text">Cпробуйте, будь ласка, пізніше</div>
        <div className="server-alert__buttons-box">
          <button
            className="button button_primary-dark button_width-max"
            type="button"
            onClick={() => toggleModal(false)}
          >
            Ок
          </button>
        </div>
      </div>
    </ModalConstructor>
  );
});
