import React from 'react';
import classNames from 'classnames';

import loader from '../../assets/images/loader.svg';
import './loader.scss';

interface Props {
  centered?: boolean;
  color?: 'black' | 'white';
}

const Loader = React.memo<Props>(({ centered, color = 'black' }) => {
  return (
    <div
      className={classNames('loader', {
        centered,
        [color]: color,
      })}
    >
      <img src={loader} alt="loader" width="50" height="50" />
    </div>
  );
});

export default Loader;
