import { useCallback, useEffect, useMemo, useState } from 'react';
import uniqueId from 'lodash/uniqueId';

export type Callback = (ok: boolean) => void;

export const useCaptcha = (cb: Callback) => {
  const [isCaptchaValid, setIsCaptchaValid] = useState<boolean>(false);
  const touchStatus = useCallback((ok: boolean) => {
    setIsCaptchaValid(ok);
    cb(ok);
  }, []);
  const [submit, expired] = useMemo(() => [uniqueId('captcha-submit-'), uniqueId('captcha-expired-')], []);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://www.google.com/recaptcha/api.js?hl=uk';
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window[submit] = () => touchStatus(true);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window[expired] = () => touchStatus(false);

    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window[submit] = null;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window[expired] = null;
    };
  }, [submit, expired, touchStatus]);

  return {
    isCaptchaValid,
    aliasSubmit: submit,
    aliasExpired: expired,
  };
};
