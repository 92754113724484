import React, { useMemo, useState } from 'react';

import FileUpload from '../FileUpload';
import InitialSelect, { SelectOption } from '../Select';
import { DocumentTypeCode } from '../../Api/types';
import { getERZDocumentsSelect } from '../../../helpers/getElementsList';
import { NullValueGuard } from '../../../guards/NullValueGuard';
import { parseFormDataValue } from '../../../helpers/parseFormDataValue';
import { PreviewFilesList } from '../PreviewFilesList';
import { AccordionData } from '../../Accordion/Accordion';
import { StepsNavigationName } from '../../../config/services';

export enum AdditionalDocumentFieldName {
  additionalDoc = 'additionalDoc',
  additionalDocType = 'additionalDocType',
  additionalDocument = 'additionalDocument',
}

export interface AdditionalDocumentFormDataProps {
  [AdditionalDocumentFieldName.additionalDoc]?: boolean;
  [AdditionalDocumentFieldName.additionalDocType]?: string;
  [AdditionalDocumentFieldName.additionalDocument]?: File[];
}
interface Props {
  formData: AdditionalDocumentFormDataProps;
  handleInputChange: (name: string, value: unknown) => void;
  handleClearInput: (name: string) => void;
}
interface PreviewProps {
  formData: AdditionalDocumentFormDataProps;
}

export const AdditionalDocument: React.FC<Props> = ({ formData, handleInputChange, handleClearInput }) => {
  const [optionsPersonDoc, setOptionsPersonDoc] = useState<SelectOption[]>([]);

  const additionalDoc = useMemo(() => formData.additionalDoc || false, [formData.additionalDoc]);
  const additionalDocType = useMemo(() => JSON.parse(formData.additionalDocType || null), [formData.additionalDocType]);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    const booleanValue = value.toLowerCase() === 'true';

    if (!booleanValue) {
      [AdditionalDocumentFieldName.additionalDocType, AdditionalDocumentFieldName.additionalDocument].forEach(
        handleClearInput,
      );
    }

    handleInputChange(name, booleanValue);
  };

  return (
    <>
      <div
        id="step-description"
        className="block-title block-title_lg block-title_padding-bottom-md-extra block-title_bg-light"
      >
        {StepsNavigationName.additionalDocument}
      </div>

      <div className="form__content">
        <div className="form__row">
          <div className="form-input form-input_margin-bottom-none">
            <div className="form-input__label form-input__label_margin-bottom-md">Ви хочете додати дані документа?</div>
            <fieldset id={AdditionalDocumentFieldName.additionalDoc} className="form-input__radiobuttons">
              <label className="radio" htmlFor={AdditionalDocumentFieldName.additionalDoc + '_yes'}>
                <input
                  className="radio__input"
                  type="radio"
                  id={AdditionalDocumentFieldName.additionalDoc + '_yes'}
                  name={AdditionalDocumentFieldName.additionalDoc}
                  value="true"
                  checked={additionalDoc}
                  onChange={handleRadioChange}
                />
                <span className="radio__checkmark"></span>
                <span className="radio__label">Так</span>
              </label>

              <label className="radio" htmlFor={AdditionalDocumentFieldName.additionalDoc + '_no'}>
                <input
                  className="radio__input"
                  type="radio"
                  id={AdditionalDocumentFieldName.additionalDoc + '_no'}
                  name={AdditionalDocumentFieldName.additionalDoc}
                  value="false"
                  checked={!additionalDoc}
                  onChange={handleRadioChange}
                />
                <span className="radio__checkmark"></span>
                <span className="radio__label">Ні</span>
              </label>
            </fieldset>
          </div>
        </div>

        {additionalDoc && (
          <>
            <div className="form-input form-input_margin-bottom-none required no-group">
              <div className="form-input__label">Тип документа</div>
              <div className="input-select js-input-select">
                <InitialSelect
                  name={AdditionalDocumentFieldName.additionalDocType}
                  options={optionsPersonDoc}
                  placeholder="Не вибрано"
                  defaultValue={additionalDocType}
                  onChange={(newValue, name) => {
                    [AdditionalDocumentFieldName.additionalDocument].forEach(handleClearInput);

                    handleInputChange(name, newValue);
                  }}
                  onMenuOpen={() => {
                    if (!optionsPersonDoc.length) {
                      getERZDocumentsSelect({
                        disallowedDocumentTypeKinds: ['A', 'B'],
                        setOptions: setOptionsPersonDoc,
                      });
                    }
                  }}
                />
              </div>
            </div>

            <div className="form-input form-input_margin-bottom-none required">
              <div className="form-input__label">
                {additionalDocType?.code === DocumentTypeCode.PHOTO_CARD ? 'Фото (3.5x4.5)' : 'Додатковий документ'}
              </div>
              <FileUpload
                key={additionalDocType?.id}
                inputName={AdditionalDocumentFieldName.additionalDocument}
                inputTitle={
                  additionalDocType?.code === DocumentTypeCode.PHOTO_CARD ? 'Додати фото' : 'Додати сканкопію документа'
                }
                value={formData.additionalDocument}
                handleInputChange={handleInputChange}
                handleClearInput={handleClearInput}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export const previewAdditionalDocument = ({ formData }: PreviewProps): AccordionData => {
  return {
    title: StepsNavigationName.additionalDocument,
    content: (
      <div className="custom-columns custom-columns_grey-bg">
        <div className="custom-columns__row custom-columns__row_padding-tiny">
          <div className="custom-columns__col">
            <div className="custom-columns__title">Тип документа</div>
            <div className="custom-columns__info">
              <NullValueGuard>
                {parseFormDataValue(formData[AdditionalDocumentFieldName.additionalDocType])?.label}
              </NullValueGuard>
            </div>
          </div>

          <div className="custom-columns__col">
            <div className="custom-columns__title">Документ</div>
            <div className="custom-columns__info">
              <NullValueGuard>
                {formData[AdditionalDocumentFieldName.additionalDocument] ? (
                  <PreviewFilesList files={formData[AdditionalDocumentFieldName.additionalDocument]} />
                ) : null}
              </NullValueGuard>
            </div>
          </div>
        </div>
      </div>
    ),
  };
};
