import React, { useContext } from 'react';

import './ModalConstructor.scss';
import ModalContext from '../../../contexts/Modal/ModalContext';

export enum ModalBorderTopColor {
  yellow = '#ffdf17',
  blue = '#1E4DBA',
  red = '#d8222f',
}

interface Props extends React.PropsWithChildren {
  modalBorderTopColor: ModalBorderTopColor;
}

export const ModalConstructor: React.FC<Props> = ({ children, modalBorderTopColor }) => {
  const { toggleModal } = useContext(ModalContext);

  return (
    <>
      <div className="modal-window" style={{ borderTopColor: modalBorderTopColor }}>
        {children}

        <button
          id="close-modal"
          aria-label="Close modal window"
          className="modal-window__close-btn close-button-default"
          type="button"
          onClick={(): void => toggleModal(false)}
        />
      </div>

      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor="close-modal" className="modal-window__close-area" />
    </>
  );
};
