import { createContext, MouseEvent } from 'react';

export interface AccessibilityContextInterface {
  turnOnAccessibilityPanel: (event: MouseEvent<HTMLElement>) => void;
}

const AccessibilityContext = createContext<AccessibilityContextInterface>({
  turnOnAccessibilityPanel: () => null,
});

export default AccessibilityContext;
