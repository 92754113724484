export interface TokensI {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  token_type: 'Bearer';
}

const getAccessToken = (): string => {
  return localStorage.getItem('accessToken');
};

const getRefreshToken = (): string => {
  return localStorage.getItem('refreshToken');
};

const saveTokens = (tokens: TokensI): void => {
  localStorage.setItem('accessToken', tokens.access_token);
  localStorage.setItem('refreshToken', tokens.refresh_token);
};

const removeTokens = (): void => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
};

export { getAccessToken, getRefreshToken, saveTokens, removeTokens };
