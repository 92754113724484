import React, { MouseEvent, useContext } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Drawer as AntdDrawer, Divider, DrawerProps } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

import AuthContext from '../../../contexts/Auth/AuthContext';
import userIcon from '../../../assets/images/user-big.svg';
import exitIcon from '../../../assets/images/exit.svg';
import { GovUaToastButton } from '../../Toasts/GovUaToast';
import { AccessibilityPanelButton } from '../AccessibilityPanel/AccessibilityPanelButton';
import './Drawer.scss';
import { MVSButton } from '../Header/MVSButton';

interface Props {
  isDrawerOpen: DrawerProps['open'];
  toggleDrawer: DrawerProps['onClose'];
  govUaToastOnClick: (e: MouseEvent<HTMLElement>) => void;
  logoutHandler: () => void;
  placement?: DrawerProps['placement'];
}

export const Drawer: React.FC<Props> = ({
  isDrawerOpen,
  toggleDrawer,
  govUaToastOnClick,
  logoutHandler,
  placement = 'right',
}) => {
  const authContext = useContext(AuthContext);
  const { pathname } = useLocation();

  return (
    <AntdDrawer open={isDrawerOpen} onClose={toggleDrawer} placement={placement} closable={false}>
      <div className="drawer">
        <div className="drawer__header">
          <CloseCircleOutlined onClick={toggleDrawer} />
        </div>
        <Divider className="drawer-divider" />
        <div className="drawer__content">
          {authContext.isLoggedIn && (
            <>
              <div className="media-title">
                {/**  TODO: store all routes in some enum MVSUSLUH-1498 * */}
                <Link to="/userarea/user/dashboard" className="media-title drawer__content-item">
                  <img className="media-title__media" src={userIcon} alt="user" width="24" height="24" />
                  <span className="media-title__title">Особистий кабінет</span>
                </Link>
              </div>
              <div className="media-title__title ">
                {/**  TODO: store all routes in some enum MVSUSLUH-1498 * */}
                <Link
                  to="/login"
                  state={{
                    nextPage: pathname,
                  }}
                  className="media-title drawer__content-item"
                  onClick={logoutHandler}
                >
                  <img className="media-title__media" src={exitIcon} alt="user" width="24" height="24" />
                  <span className="media-title__title">Вийти</span>
                </Link>
              </div>
            </>
          )}
          {!authContext.isLoggedIn && (
            <div className="media-title">
              {/**  TODO: store all routes in some enum MVSUSLUH-1498 * */}
              <NavLink to="/login" className="media-title drawer-content__item" onClick={toggleDrawer}>
                <img className="media-title__media" src={userIcon} alt="user" width="24" height="24" />
                <span className="media-title__title">Увійти</span>
              </NavLink>
            </div>
          )}
        </div>
        <div className="drawer__footer">
          <Divider className="drawer-divider" />
          <div className="drawer__footer-content">
            <div className="drawer__footer-item">
              <AccessibilityPanelButton onClick={(e): void => toggleDrawer(e)} />
            </div>
            <div className="drawer__footer-item">
              <MVSButton />
            </div>
            <div>
              <GovUaToastButton govUaToastHandler={govUaToastOnClick} />
            </div>
          </div>
        </div>
      </div>
    </AntdDrawer>
  );
};
