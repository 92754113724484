import { useCallback, useState } from 'react';

type Callback<D, P extends unknown[] = []> = (...args: P) => Promise<D>;
export type HookReturn<D, P extends unknown[] = []> = {
  data: null | D;
  isLoading: boolean;
  load: (...args: P) => Promise<void>;
  change: (input: D) => void;
};

type Options<D> = {
  mutate?: (data: D) => Promise<D>;
  defaultDataValue?: D;
};

export const useAsyncDataLoader = <D, P extends unknown[] = []>(
  cb: Callback<D, P>,
  options?: Options<D>,
): HookReturn<D, P> => {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState<D | null>(options?.defaultDataValue || null);

  const load = useCallback(
    async (...args: P) => {
      try {
        setLoading(true);
        setData(await (options?.mutate || ((i: D): D => i))(await cb(...args)));
      } finally {
        setLoading(false);
      }
    },
    [cb, options?.mutate],
  );

  return {
    change: setData,
    isLoading,
    data,
    load,
  };
};
