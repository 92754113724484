import React from 'react';

export const ListSearchResult: React.FC<Record<'result', Array<[string, string]>>> = React.memo(({ result }) => (
  <div className="extract">
    {result.map(([fieldName, value]) => (
      <div key={fieldName} className="extract__field">
        <div className="extract__field__name">{fieldName}</div>
        <div className="extract__field__value">{value}</div>
      </div>
    ))}
  </div>
));
