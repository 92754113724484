import React, { memo, useContext, useState } from 'react';
import { Divider, Button as AntdButton } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

import { ModalBorderTopColor, ModalConstructor } from '../ModalConstructor';
import { DownloadButton } from '../../Forms/Buttons';
import { Button } from '../../Button';
import ModalContext from '../../../contexts/Modal/ModalContext';
import { api } from '../../Api';
import { PaymentDetailsFieldName, ResponsePaymentDetails } from '../../Api/types';
import { saveFileFromBlob } from '../../../helpers/fetch/saveFileFromBlob';
import './PaymentDetailsAlert.scss';

export interface PaymentDetailsAlertProps {
  detailsList: Omit<ResponsePaymentDetails, PaymentDetailsFieldName.fee>;
  currentRequestId: number;
}

const PaymentDetailsFieldsMapping = {
  [PaymentDetailsFieldName.price]: 'Сума (грн)',
  [PaymentDetailsFieldName.receiver]: 'Отримувач',
  [PaymentDetailsFieldName.edrpou]: 'ЄДРПОУ отримувача',
  [PaymentDetailsFieldName.iban]: 'Розрахунковий рахунок (IBAN)',
  [PaymentDetailsFieldName.purpose]: 'Призначення платежу',
};

const HEADER_TEXT = 'Реквізити для оплати';

export const PaymentDetailsAlert = memo(function PayOrLeaveModal({
  detailsList,
  currentRequestId,
}: PaymentDetailsAlertProps) {
  const { toggleModal } = useContext(ModalContext);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const downloadButtonAction = (): void => {
    setIsDownloading(true);
    api.services
      .getPaymentDetails<Blob>(currentRequestId, true)
      .then((blob) => saveFileFromBlob(`${HEADER_TEXT}.pdf`, blob))
      .finally(() => setIsDownloading(false));
  };

  return (
    <ModalConstructor modalBorderTopColor={ModalBorderTopColor.blue}>
      <div className="payment-details-modal">
        <div className="payment-details-modal__title">{HEADER_TEXT}</div>

        <Divider />

        <div className="details-list">
          {Object.entries(detailsList).map((details) => (
            <div key={details[0]}>
              <div className="details-header">
                {PaymentDetailsFieldsMapping[details[0] as keyof typeof PaymentDetailsFieldsMapping]}
              </div>
              <div className="details-text-container">
                <div className="details-text">{details[1]}</div>
                <AntdButton
                  type="text"
                  aria-label="Копіювати"
                  color="primary"
                  shape="circle"
                  className="copyButton"
                  onClick={(): Promise<void> => navigator.clipboard.writeText(details[1] as string)}
                  icon={<CopyOutlined />}
                />
              </div>
            </div>
          ))}
        </div>

        <Divider />

        <div className="payment-details-modal__buttons-box">
          <DownloadButton
            outline
            text="Завантажити"
            onClick={downloadButtonAction}
            disabled={isDownloading}
            withStyles={false}
            downloading={isDownloading}
          />
          <Button
            onClick={(): void => {
              toggleModal(false);
            }}
          >
            Закрити
          </Button>
        </div>
      </div>
    </ModalConstructor>
  );
});
