import React, { useReducer, ReactNode, createContext, useEffect } from 'react';

import { saveTokens, removeTokens, TokensI, getAccessToken } from './authTokens';
import { AUTH, authReducer } from './authReducer';
import { api } from '../../components/Api';
import {
  IdGovUaData,
  UserData,
  DocumentData,
  IntegrationSystem,
  ResponseUserData,
  ResponseStoredLinks,
  SocialProtectData,
} from '../../components/Api/types';
import { HookReturn, useAsyncDataLoader } from '../../hooks/useAsyncDataLoader';
import { die, on } from '../../eventBus';
import { singInWithPassword } from '../../components/Api/auth';
import { getStoredLinks } from '../../components/Api/apiService';

export interface UserPersonalInfo {
  userData: UserData;
  documentData: DocumentData;
  idGovData: IdGovUaData;
  fullName: string;
  systems: IntegrationSystem[];
  socialProtect?: SocialProtectData;
  extra?: boolean;
}

export interface AuthContextInterface extends UserPersonalInfo {
  isLoggedIn: boolean;
  storedLinks: ResponseStoredLinks;
  loginWithPassword: (password: string, email: string) => Promise<void>;
  loginWithTokens: (input: TokensI) => void;
  logout: () => void;
  updateUserData: HookReturn<ResponseUserData>['change'];
}

const AuthContext = createContext<AuthContextInterface>({
  fullName: '',
  idGovData: {} as IdGovUaData,
  userData: {} as UserData,
  documentData: {} as DocumentData,
  isLoggedIn: false,
  loginWithPassword: async () => null,
  loginWithTokens: () => null,
  logout: () => null,
  updateUserData: () => null,
  systems: [],
  storedLinks: {} as ResponseStoredLinks,
  socialProtect: {} as SocialProtectData,
});

interface Props {
  children: ReactNode;
}

export const AuthContextProvider = ({ children }: Props): JSX.Element => {
  const [{ isLoggedIn }, dispatch] = useReducer(authReducer, {
    isLoggedIn: !!getAccessToken(),
  });

  const loginWithPassword = async (password: string, email: string): Promise<void> => {
    await singInWithPassword(email, password);

    login();
  };

  const loginWithTokens = (tokens: TokensI): void => {
    saveTokens(tokens);
    login();
  };

  const login = (): void => {
    dispatch({ type: AUTH.LOGIN });
  };

  const logoutHandler = (): void => {
    removeTokens();
    dispatch({ type: AUTH.LOGOUT });
  };

  useEffect(() => {
    on('logout', logoutHandler);
    on('login', login);
    on('update_profile', loadProfile);
    loadStoredLinks();

    return () => {
      die('login');
      die('logout');
      die('update_profile');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    data: storedLinks,
    load: loadStoredLinks,
    isLoading: isLoadingLinks,
  } = useAsyncDataLoader(() => getStoredLinks());

  const {
    data: profile,
    load: loadProfile,
    change,
    isLoading: isLoadingProfile,
  } = useAsyncDataLoader(() => api.getUserData());

  const {
    data: { idgovua: idGovData, user: userData, documents: documentData, systems, msp: socialProtect },
  } = profile || { data: { user: null, requests: [], idgovua: null, msp: null } };

  useEffect(() => {
    isLoggedIn && loadProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  if (isLoadingLinks || isLoadingProfile) {
    return null;
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        logout: logoutHandler,
        loginWithTokens,
        loginWithPassword,
        fullName: [userData?.name_first, userData?.name_last].join(' '),
        userData,
        updateUserData: change,
        documentData,
        idGovData,
        systems,
        storedLinks,
        socialProtect,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
