import { useCallback, useState } from 'react';

import { api } from '../../components/Api';
import { FeatureName, ResponseFeatures } from '../../components/Api/types';

interface UseFeatureManagementHookReturn {
  load: (force?: boolean) => void;
  isFeatureAvailable: (key: FeatureName) => boolean;
  clear: () => void;
  isLoading: boolean;
  getDetails: <T extends FeatureName>(key: T) => ResponseFeatures[T][1];
}

export const useFeatureManagement = (): UseFeatureManagementHookReturn => {
  const [isLoading, setLoading] = useState(false);
  const [features, saveFeatures] = useState<ResponseFeatures>();

  const load = useCallback(async (force = false) => {
    try {
      setLoading(true);
      const data = await api.getFeatures(force);
      saveFeatures(data);
    } catch (e: unknown) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      console.warn(`can not load features: ${e?.message || String(e)}`);
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, []);

  const clear = useCallback(() => {
    saveFeatures({} as ResponseFeatures);
  }, []);

  const isFeatureAvailable: UseFeatureManagementHookReturn['isFeatureAvailable'] = useCallback(
    (key) => !!features?.[key][0],
    [features],
  );
  const getDetails: UseFeatureManagementHookReturn['getDetails'] = useCallback((key) => features?.[key][1], [features]);

  return {
    load,
    isFeatureAvailable,
    getDetails,
    clear,
    isLoading,
  };
};
