import React, { InputHTMLAttributes, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import classNames from 'classnames';

import { ValidateInputValueResult } from '../Validation';
import { FormConstants } from '../constants';

interface BaseProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  value: string;
  label?: string;
  change: (name: string, value: unknown) => void;
  validator?: (value: string) => ValidateInputValueResult;
}

interface PropsWithMask extends BaseProps {
  withMask: true;
  mask: string;
  inputMaskType: React.HTMLInputTypeAttribute;
}

interface PropsWithoutMask extends BaseProps {
  withMask?: false;
  mask?: never;
  inputMaskType?: never;
}

export type InputFieldProps = PropsWithMask | PropsWithoutMask;

export const InputField: React.FC<InputFieldProps> = ({
  name,
  value = '',
  placeholder = 'Не вказано',
  label = '',
  required = false,
  disabled = false,
  withMask = false,
  mask = '',
  inputMaskType = '',
  change,
  validator,
  ...inputHTMLAttributes
}) => {
  const [inputError, setInputError] = useState<string>('');

  useEffect(() => {
    if (value && validator) {
      const { isValid, error } = validator(value);
      setInputError(isValid ? '' : error);
    }
  }, []);

  return (
    <div
      className={classNames('form-input', 'form-input_margin-bottom-none', {
        'disabled-div': disabled,
        'error-div': !!inputError,
        required,
      })}
    >
      {label && <div className="form-input__label">{label}</div>}
      {withMask ? (
        <InputMask
          {...inputHTMLAttributes}
          type={inputMaskType}
          mask={mask}
          placeholder={placeholder}
          className="form-input__input form-control"
          name={name}
          value={value}
          onChange={({ target }): void => {
            change(target.name, target.value.trim());
            setInputError('');
          }}
          onBlur={(event): void => {
            if (validator) {
              const { isValid, error } = validator(event.target.value);
              setInputError(isValid ? '' : error);
            }
          }}
        />
      ) : (
        <input
          {...inputHTMLAttributes}
          className="form-input__input"
          placeholder={placeholder}
          disabled={disabled}
          type="text"
          name={name}
          value={value}
          onChange={({ target }): void => {
            change(target.name, target.value.trim());
            setInputError('');
          }}
          onBlur={(event): void => {
            if (validator) {
              const { isValid, error } = validator(event.target.value);
              setInputError(isValid ? '' : error);
            }
          }}
          maxLength={inputHTMLAttributes.maxLength || FormConstants.INPUT_MAX_LENGTH}
        />
      )}
      {inputError && (
        <div className="error-msg" key={inputError}>
          {inputError}
        </div>
      )}
    </div>
  );
};
