import { saveTokens, TokensI } from '../../contexts/Auth/authTokens';
import { request } from './client';

export const singInWithPassword = async (email: string, password: string): Promise<void> => {
  const response = await request(`/oauth/token`, 'POST', {
    password,
    email,
  });

  if (!response.ok) {
    throw new Error(await response.text());
  }

  const tokens: TokensI = await response.json();

  saveTokens(tokens);
};

export const refreshTokens = async (refreshToken: string): Promise<void> => {
  const response = await request('/oauth/refresh', 'POST', {
    refresh_token: refreshToken,
  });

  if (!response.ok) throw new Error('can not update refresh token');

  const tokens: TokensI = await response.json();

  saveTokens(tokens);
};
