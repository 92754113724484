import React from 'react';
import { Link } from 'react-router-dom';

import { AccessibilityPanelButton } from '../AccessibilityPanel/AccessibilityPanelButton';
import facebook from '../../../assets/images/facebook.svg';
import twitter from '../../../assets/images/twitter.svg';
import youtube from '../../../assets/images/youtube.svg';
// import instagram from '../../../assets/images/instagram.svg'
// import telegram from '../../../assets/images/telegram.svg'
// import rss from '../../../assets/images/rss.svg'
// import telephone from '../../../assets/images/telephone.svg'

import './Footer.scss';

const Footer = (): JSX.Element => {
  const handleAccessibilityClick = (): void => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <footer className="footer" id="main-footer">
      <div className="container">
        <div className="footer__nav">
          <div className="footer__socials">
            <a
              className="footer__social-link"
              href="https://www.facebook.com/mvs.gov.ua"
              target="_blank"
              rel="noreferrer"
            >
              <img className="footer__social-icon" src={facebook} alt="facebook" width="24" height="24" />
            </a>
            <a className="footer__social-link" href="https://twitter.com/mvs_ua" target="_blank" rel="noreferrer">
              <img className="footer__social-icon" src={twitter} alt="twitter" width="24" height="23" />
            </a>
            <a
              className="footer__social-link"
              href="https://www.youtube.com/channel/UCv2HKGiEEtHQguOsn845GpA"
              target="_blank"
              rel="noreferrer"
            >
              <img className="footer__social-icon" src={youtube} alt="youtube" width="24" height="20" />
            </a>
            {/* <a
              className="footer__social-link"
              href="https://www.instagram.com/mvs_ua"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="footer__social-icon"
                src={instagram}
                alt="instagram"
                width="24"
                height="24"
              />
            </a>
            <a
              className="footer__social-link"
              href="https://t.me/mvs_ukraine"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="footer__social-icon"
                src={telegram}
                alt="telegram"
                width="24"
                height="24"
              />
            </a> */}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            {/* <a className="footer__social-link" href="#" target="_blank">
              <img
                className="footer__social-icon"
                src={rss}
                alt="rss"
                width="65"
                height="28"
              />
            </a> */}
          </div>
        </div>
        <div className="footer__content">
          <div className="footer__col footer__col_info">
            <div className="footer__info">
              <div className="footer__info-text">Якщо не зазначено інше всі матеріали розміщені на умовах ліцензії</div>
              <a
                className="footer__info-link"
                href="https://creativecommons.org/licenses/by/4.0/deed.uk"
                target="_blank"
                rel="noreferrer"
              >
                Creative Commons Attribution 4.0 International license
              </a>
              {/* <div className="footer__info-text">
                Розробник порталу
                <a
                  className="footer__info-link footer__info-link_ml-tiny"
                  href="https://infotech.gov.ua/"
                  target="_blank"
                  rel="noreferrer"
                >
                  ДП «Інфотех»
                </a>
              </div> */}
            </div>
          </div>
          <div className="footer__col footer__col_contacts">
            <div className="block-title block-title_sm block-title_lighter">КОНТАКТИ</div>
            <div className="block-title block-title_sm block-title_lighter">
              <div>Довідка щодо функціонування порталу:</div>
              <div>
                <a href="mailto:services@infotech.gov.ua" target="_blank" rel="noreferrer">
                  services@infotech.gov.ua
                </a>
              </div>
            </div>
          </div>
          <div className="footer__col footer__col_buttons">
            <div className="footer__info-buttons">
              <AccessibilityPanelButton onClick={handleAccessibilityClick} />
            </div>
          </div>

          <div className="footer__col footer__col_policy">
            <Link to="/personal_data" target="_blank" rel="noreferrer">
              Повідомлення про обробку персональних даних
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
