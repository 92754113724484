import React, { memo, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { ModalBorderTopColor, ModalConstructor } from '../ModalConstructor';
import ModalContext from '../../../contexts/Modal/ModalContext';
import './DocumentDataMissingAlert.scss';

export interface DocumentDataMissingAlertProps {
  serviceName: string;
}

export const DocumentDataMissingAlert = memo(function DocumentDataMissingAlert({
  serviceName,
}: DocumentDataMissingAlertProps) {
  const { toggleModal } = useContext(ModalContext);
  const navigate = useNavigate();

  return (
    <ModalConstructor modalBorderTopColor={ModalBorderTopColor.yellow}>
      <div className="birthday-alert">
        <div className="birthday-alert__title">
          Для замовлення послуги &quot;{serviceName}&quot; необхідно заповнити всі обов&apos;язкові поля в розділі
          Профіль користувача.
        </div>

        <div className="birthday-alert__buttons-box">
          <button className="button button_border button_width-max" type="button" onClick={() => toggleModal(false)}>
            Закрити
          </button>
          <button
            className="button button_width-max"
            type="button"
            onClick={() => {
              navigate('/userarea/user/profile');
              toggleModal(false);
            }}
          >
            Перейти у профіль
          </button>
        </div>
      </div>
    </ModalConstructor>
  );
});
