import { createContext } from 'react';

import { FeatureName, IntegrationSystem, ResponseFeatures } from '../../components/Api/types';

export type FeatureContextType = {
  isFeatureAvailable: (key: FeatureName) => boolean;
  getFeatureDetails: <T extends FeatureName>(key: T) => ResponseFeatures[T][1];
  getSourceSystemByFeature: (key: FeatureName) => IntegrationSystem | null;
};

export const FeatureContext = createContext<FeatureContextType>({
  isFeatureAvailable: () => false,
  getFeatureDetails: () => null,
  getSourceSystemByFeature: () => null,
});
