import React from 'react';
import { Link } from 'react-router-dom';
import './OrderServiceLink.scss';

export const OrderServiceLink: React.FC = () => {
  return (
    <Link className="order-service-link" to="/#services">
      Замовити послугу
    </Link>
  );
};
