import React, { useContext, useEffect } from 'react';
import { first } from 'lodash';

import { useFeatureManagement } from './userFeaturesManagement';
import { FeatureContext } from './FeatureContext';
import { FeatureName, IntegrationSystem } from '../../components/Api/types';
import AuthContext from '../Auth/AuthContext';
import { die, on } from '../../eventBus';

type Props = {
  children: React.JSX.Element;
};

const getSourceSystemByFeature = (key: FeatureName): IntegrationSystem => {
  const system = first(key?.split('.')) || '';
  const prefix = '_services';

  return (system.replace(prefix, '') as IntegrationSystem) || null;
};

export const FeatureProvider: React.FC<Props> = ({ children }) => {
  const { isLoggedIn } = useContext(AuthContext);
  const { isFeatureAvailable, load, getDetails } = useFeatureManagement();

  useEffect(() => {
    on('update_features', () => load(true));

    return () => {
      die('update_features');
    };
  }, [load]);

  useEffect(() => {
    isLoggedIn && load();
  }, [isLoggedIn, load]);

  return (
    <FeatureContext.Provider
      value={{
        isFeatureAvailable,
        getFeatureDetails: getDetails,
        getSourceSystemByFeature,
      }}
    >
      {children}
    </FeatureContext.Provider>
  );
};
