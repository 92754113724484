import React, { memo, useContext } from 'react';
import './LeavingWhileInProgressAlert.scss';

import { ModalBorderTopColor, ModalConstructor } from '../ModalConstructor';
import ModalContext from '../../../contexts/Modal/ModalContext';
import { Button } from '../../Button';

export interface LeavingWhileInProgressAlertProps {
  onStay?: () => void;
  onLeave?: () => void;
}

export const LeavingWhileInProgressAlert = memo<LeavingWhileInProgressAlertProps>(function ({ onStay, onLeave }) {
  const { toggleModal } = useContext(ModalContext);

  return (
    <ModalConstructor modalBorderTopColor={ModalBorderTopColor.red}>
      <div className="leaving-alert">
        <div className="leaving-alert__title">
          <img src="/images/attention_filled_red.svg" alt="attention filled red" />
          <div className="block-title_lg">Увага, ви закриваєте запит</div>
          <span>Введені дані після закриття будуть втрачені</span>
        </div>

        <div className="leaving-alert__buttons-box">
          <Button
            onClick={(): void => {
              onStay && onStay();
              toggleModal(false);
            }}
          >
            Продовжити створення
          </Button>

          <Button
            outline
            onClick={(): void => {
              onLeave && onLeave();
              toggleModal(false);
            }}
          >
            Закрити запит
          </Button>
        </div>
      </div>
    </ModalConstructor>
  );
});
