import React, { memo, useContext } from 'react';

import { ModalBorderTopColor, ModalConstructor } from '../ModalConstructor';
import ModalContext from '../../../contexts/Modal/ModalContext';
import './PayOrLeaveAlert.scss';
import { Button } from '../../Button';

export interface PayOrLeaveAlertProps {
  payButtonAction: () => void;
  onDeleteCallback: () => void;
}

export const PayOrLeaveAlert = memo(function PayOrLeaveModal({
  payButtonAction,
  onDeleteCallback,
}: PayOrLeaveAlertProps) {
  const { toggleModal } = useContext(ModalContext);

  return (
    <ModalConstructor modalBorderTopColor={ModalBorderTopColor.blue}>
      <div className="pay-modal">
        <div className="pay-modal__title">У вас вже є створена заява, яка очікує на оплату</div>

        <div className="pay-modal__primary-text">
          Оплатіть послугу або зачекайте на анулювання запиту впродовж 14 днів
        </div>

        <div className="pay-modal__buttons-box">
          <Button
            outline
            bgColor="error"
            onClick={() => {
              toggleModal(false);
              onDeleteCallback();
            }}
          >
            Видалити заяву
          </Button>
          <Button
            onClick={() => {
              toggleModal(false);
              payButtonAction();
            }}
          >
            Оплатити
          </Button>
        </div>
      </div>
    </ModalConstructor>
  );
});
