const API_HOST = process.env.REACT_APP_API_HOST;

export const request = async (
  url: string,
  method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE',
  data: unknown,
  headers: Headers = new Headers(),
): Promise<Response> => {
  const common = {
    method,
  };

  if (!headers.has('Accept')) {
    headers.set('Accept', 'application/json');
  }

  let body = undefined;
  if (data !== null) {
    body = JSON.stringify(data);
    headers.set('Content-Type', 'application/json');
  }

  return fetch(API_HOST + url, {
    ...common,
    body,
    headers,
  });
};
