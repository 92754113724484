/* eslint-disable prettier/prettier */
import React, { memo, useContext } from 'react';

import { ModalBorderTopColor, ModalConstructor } from '../ModalConstructor';
import ModalContext from '../../../contexts/Modal/ModalContext';

import '../BirthdayUpdateAlert/BirthdayUpdateAlert.scss';

export const AddresCheckAlert = memo(function AddresCheckAlert() {
  const { toggleModal } = useContext(ModalContext);

  return (
    <ModalConstructor modalBorderTopColor={ModalBorderTopColor.yellow}>
      <div className="birthday-alert">
        <div className="birthday-alert__title">
          Нова адреса місця зберігання має відрізнятися від Вашого зареєстрованого місця проживання
        </div>

        <div className="birthday-alert__buttons-box">
          <button className="button button_border button_width-max" type="button" onClick={() => toggleModal(false)}>
            Закрити
          </button>
        </div>
      </div>
    </ModalConstructor>
  );
});
