export type GovUaToast = {
  title: string;
  ref: string;
};

export const govUaToastConfig: GovUaToast[] = [
  {
    title: 'Президент України',
    ref: 'https://www.president.gov.ua/',
  },
  {
    title: 'Міністерство юстиції України',
    ref: 'https://minjust.gov.ua/',
  },
  {
    title: 'Верховна Рада України',
    ref: 'https://www.rada.gov.ua/',
  },
  {
    title: 'Урядовий портал',
    ref: 'https://www.kmu.gov.ua/',
  },
];
