import React, { memo, useContext } from 'react';

import { ModalBorderTopColor, ModalConstructor } from '../ModalConstructor';
import ModalContext from '../../../contexts/Modal/ModalContext';
import './ServiceDraftExistingAlert.scss';

export interface ServiceDraftExistingAlertProps {
  startNewDraft: () => void;
  continueDraft: () => void;
}

export const ServiceDraftExistingAlert = memo(function ServiceDraftExistingAlert({
  startNewDraft,
  continueDraft,
}: ServiceDraftExistingAlertProps) {
  const { toggleModal } = useContext(ModalContext);

  return (
    <ModalConstructor modalBorderTopColor={ModalBorderTopColor.blue}>
      <div className="service-exist-alert">
        <div className="service-exist-alert__title">
          <div className="block-title_md">Заява на дану послугу вже знаходиться у чернетці</div>
          <span>Бажаєте продовжити заповнення, чи почати заново?</span>
        </div>

        <div className="service-exist-alert__buttons-box">
          <button
            className="button button_border button_width-max"
            type="button"
            onClick={() => {
              startNewDraft();
              toggleModal(false);
            }}
          >
            Почати заново
          </button>
          <button
            className="button button_width-max"
            type="button"
            onClick={() => {
              continueDraft();
              toggleModal(false);
            }}
          >
            Продовжити
          </button>
        </div>
      </div>
    </ModalConstructor>
  );
});
