export interface ValidateInputValueResult {
  error?: string;
  isValid: boolean;
}

const isEmailRegExp =
  /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+(?:[a-zA-Z]{2}|com|org|net|gov|mil|biz|info|jobs|ua|test)\b$/;

export const isLettersAndDigits = (value: string): boolean => /^[a-zA-Zа-яА-ЯІіЇїЄє\s-'’,]+$/.test(value);
export const isRussianLetters = (value: string): boolean => /^[ЭЪЫЁэъыё]+$/i.test(value);
export const isLatinLetters = (value: string): boolean => /^[A-Za-z]+$/.test(value);
export const isNumber = (value: string): boolean => /^[0-9]$/.test(value);
export const isNotDigit = (value: string): boolean => !/\d/.test(value);
export const isDateTimeISO = (value: string): boolean =>
  /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?(Z|[+-]\d{2}:\d{2})$/.test(value);
export const isCyrylicaAndLatinLetters = (value: string): boolean =>
  /^[АБВГҐДЕЄЖЗИІЙЇКЛМНОПРСТУФХЦЧШЩЬЮЯA-Z]+$/.test(value);
export const isCyrylicaLetters = (value: string): boolean =>
  /(^[А-ЩЬЮЯЇІЄҐ]+([ ''-][А-ЩЬЮЯЇІЄҐ]+)*$)|(^[A-Z]+([ ''-][A-Z]+)*$)', 'c/.test(value);

const invalidEmailSymbols = ['"', '(', ')', ',', ':', ';', '<', '>', '[', ']', '№'];
const forbiddenEmailsList = ['yandex', 'mail.ua', 'mail.ru', 'ru', 'example', 'inbox', 'list', 'bk'];
const ukrOperatorsPhoneCodes = {
  Kyivstar: ['039', '067', '068', '096', '097', '098'],
  Vodafone: ['050', '066', '095', '099'],
  Lifecell: ['063', '073', '093'],
  Utel: ['091'],
  PEOPLEnet: ['092'],
  Intertelecom: ['089', '094'],
};

const isForbiddenEmail = (email: string): boolean => {
  const emailDomain = email.split('@')?.[1]?.toLowerCase() || '';

  return forbiddenEmailsList.some(
    (forbiddenEmail) => emailDomain === forbiddenEmail || emailDomain.split('.').includes(forbiddenEmail),
  );
};

export const validateEmail = (value = ''): ValidateInputValueResult => {
  const emailParts = value?.split('@');
  const localPart = emailParts?.[0];
  const domainPart = emailParts?.[1]?.toLowerCase();

  const isFullEmailLengthValid = value.length <= 254;
  const isLocalPartLengthValid = localPart?.length <= 64;
  const isDomainPartLengthValid = domainPart?.split('.').every((domain) => domain.length <= 64);

  const isEmail = isEmailRegExp.test([localPart, domainPart].join('@'));

  const withoutForbiddenDomains = !isForbiddenEmail(value);
  const withoutSymbols = value.split('').every((symbol) => !invalidEmailSymbols.includes(symbol));

  const isLengthValid = isFullEmailLengthValid && isLocalPartLengthValid && isDomainPartLengthValid;
  const isValid = isEmail && withoutForbiddenDomains && isLengthValid && withoutSymbols;

  let error = '';

  if (!isValid) {
    switch (false) {
      case !!value:
        error = 'Необхідно заповнити поле';
        break;
      case withoutSymbols:
      case isDomainPartLengthValid:
      case isLocalPartLengthValid:
        error = 'Недопустимий Email';
        break;

      case isFullEmailLengthValid:
        error = 'Введений Email перевищує максимальну дозволену кількість символів';
        break;

      case withoutForbiddenDomains:
        error = 'Недопустимий поштовий сервіс';
        break;

      case isEmail:
        error = 'Email має бути в форматі example@example.com';
        break;

      default:
        break;
    }
  }
  return { isValid, error };
};

export const validateField = (value = ''): ValidateInputValueResult => {
  const error = value ? '' : 'Необхідно заповнити поле';

  return { error, isValid: !error };
};

export const validatePhone = (value = '', ignorePhoneCodeValidation = false): ValidateInputValueResult => {
  const valueWithoutPhoneMask = value.replace(/[()\s-]/g, '');

  const isPhone = /\+\d{12}/.test(valueWithoutPhoneMask);
  const isUkrPhoneCode =
    ignorePhoneCodeValidation ||
    Object.values(ukrOperatorsPhoneCodes).flat(1).includes(valueWithoutPhoneMask.slice(3, 6));

  const isValid = isPhone && isUkrPhoneCode;
  const error = !isValid
    ? (!isPhone && 'Неправильно вказаний номер телефону') || (!isUkrPhoneCode && 'Недопустимий формат телефону')
    : '';

  return { isValid, error };
};

export const validateHouseDetails = (value = ''): ValidateInputValueResult => {
  const valueWithoutDigitsAndSpecialSymbols = value.replace(/[0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/g, '');

  const isLettersValid =
    !valueWithoutDigitsAndSpecialSymbols.length ||
    isCyrylicaAndLatinLetters(valueWithoutDigitsAndSpecialSymbols.toUpperCase());
  const isValid = !!value && isLettersValid;
  const error = (!isLettersValid && 'Лише кирилиця і латиниця') || '';

  return {
    isValid,
    error,
  };
};

export const validateFieldForNonZeroValue = (value = ''): ValidateInputValueResult => {
  const isNumericValue = !isNaN(+value);
  const isValidNumberRange = isNumericValue && +value > 0;

  return { isValid: isValidNumberRange };
};

export const validateFieldCyrillicaLetters = (value = ''): ValidateInputValueResult => {
  const isLettersValid = !value || isCyrylicaLetters(value);
  const error = (!isLettersValid && 'Тільки українською мовою') || '';

  return {
    isValid: isLettersValid,
    error,
  };
};
