import { ServiceCodes, UserAreaRoutes } from '../components/Api/types';

export const decoratePrivateRoute = (i: string): string => '/userarea/user' + i;

export const ServiceRoutes = {
  'erz.search': '/erzsearch',
  'weapon.registered': decoratePrivateRoute(UserAreaRoutes.weaponRegistered),
  'erz.extract': decoratePrivateRoute(UserAreaRoutes.erzExtract),
  [ServiceCodes.CRIMINAL_RECORD_SEARCH]: UserAreaRoutes.criminalRecordSearch,
  [ServiceCodes.CRIMINAL_RECORD_SEARCH_ALL]: decoratePrivateRoute(UserAreaRoutes.criminalRecordSearchAll),
  [ServiceCodes.PURCHASE]: decoratePrivateRoute(UserAreaRoutes.weaponPurchasePermit),
  [ServiceCodes.VALIDITY_PERIOD_PROLONGATION]: decoratePrivateRoute(UserAreaRoutes.permissionRenewal),
  [ServiceCodes.RESIDENCE_RELOCATION]: decoratePrivateRoute(UserAreaRoutes.residenceRelocation),
  [ServiceCodes.TEMPORARY_RESIDENCE_RELOCATION]: decoratePrivateRoute(UserAreaRoutes.temporaryResidenceRelocation),
  [ServiceCodes.COMMISSION_SALE]: decoratePrivateRoute(UserAreaRoutes.commissionSales),
  [ServiceCodes.COLD_STEEL_TRANSPORTATION]: decoratePrivateRoute(UserAreaRoutes.collectableWeaponsTransportation),
  [ServiceCodes.TRANSPORT_ACROSS_BORDER]: decoratePrivateRoute(UserAreaRoutes.weaponTransportation),
  [ServiceCodes.STORAGE_CARRYING]: decoratePrivateRoute(UserAreaRoutes.weaponStorage),
  [ServiceCodes.MISSING_PERSON]: decoratePrivateRoute(UserAreaRoutes.missingPerson),
  [ServiceCodes.COLD_STEEL_STORAGE_FOR_COLLECTION]: decoratePrivateRoute(UserAreaRoutes.coldSteelStorageForCollection),
  [ServiceCodes.CRIMINAL_RECORD_EXTRACT]: decoratePrivateRoute(UserAreaRoutes.criminalRecordExtract),
  [ServiceCodes.SOCIAL_PROTECT]: decoratePrivateRoute(UserAreaRoutes.socialProtect),
  [ServiceCodes.CANCELLATION_TEMPORARY_RESIDENCE_RELOCATION]: decoratePrivateRoute(
    UserAreaRoutes.cancellationTemporaryResidenceRelocation,
  ),
} as Record<string, string>;

// TODO: move to generated dictionaries from /services endpoint
export const ServiceNames = {
  [ServiceCodes.ERZ_EXTRACT]: 'Отримання витягу з реєстру зброї',
  [ServiceCodes.ERZ_SEARCH]: 'Перевірка витягу з Єдиного реєстру зброї',
  [ServiceCodes.IMPORT_IN_UKRAINE]:
    'Заява на перевезення зброї, боєприпасів фізичною особою через митний кордон України',
  [ServiceCodes.TRANSPORT_THROUGH_UKRAINE]:
    'Заява на перевезення зброї, боєприпасів фізичною особою територією України',
  [ServiceCodes.VALIDITY_PERIOD_PROLONGATION]: 'Заява про отримання дозволу на продовження терміну дії дозволу',
  [ServiceCodes.RESIDENCE_RELOCATION]: 'Заява про отримання дозволу на зміну місця проживання власника зброї',
  [ServiceCodes.TEMPORARY_RESIDENCE_RELOCATION]: 'Заява на отримання дозволу на тимчасову зміну місця зберігання зброї',
  [ServiceCodes.CANCELLATION_TEMPORARY_RESIDENCE_RELOCATION]:
    'Заява на повернення після тимчасової зміни місця зберігання зброї',
  [ServiceCodes.COLD_STEEL_TRANSPORTATION]:
    'Заява про отримання дозволу на перевезення холодної колекційної зброї територією України',
  [ServiceCodes.COMMISSION_SALE]:
    'Подання заяви про надання направлення на комісійний продаж вогнепальної мисливської зброї, основних частин зброї, пневматичної, холодної, охолощеної зброї, пристроїв',
  [ServiceCodes.PURCHASE]: 'Заява про надання дозволу на придбання',
  [ServiceCodes.STORAGE_CARRYING]:
    'Подання заяви про надання дозволу на зберігання, носіння вогнепальної мисливської зброї, основних частин зброї, пневматичної, холодної, охолощеної зброї, пристроїв, крім нагородної',
  [ServiceCodes.TRANSPORT_ACROSS_BORDER]:
    'Заява про надання дозволу на перевезення зброї через митний кордон України (вивезення - ввезення)',
  [ServiceCodes.MISSING_PERSON]:
    'Подача анкети про зниклу особу безвісти за особливих обставин до уповноваженого з питань осіб зниклих безвісти за особливих обставин',
  [ServiceCodes.COLD_STEEL_STORAGE_FOR_COLLECTION]:
    'Заява на отримання дозволу на зберігання холодної зброї з метою колекціонування',
  [ServiceCodes.CRIMINAL_RECORD_EXTRACT]: 'Запит на отримання витягу про несудимість',
  [ServiceCodes.CRIMINAL_RECORD_SEARCH]: 'Перевірка витягу про несудимість',
  [ServiceCodes.CRIMINAL_RECORD_SEARCH_ALL]: 'Мої витяги про несудимість',
  [ServiceCodes.SOCIAL_PROTECT]: 'Соціальний захист',
};

export enum StepsNavigationName {
  serviceDescription = 'Опис послуги',
  coldWeapons = 'Дані по холодній зброї',
  weaponData = 'Дані про зброю',
  permissionData = 'Дані про дозвіл',
  expertResearch = 'Дані експертних досліджень',
  personalData = 'Особисті дані',
  personalAndPassport = 'Особисті та паспортні дані',
  currentPlaceOfResidence = 'Поточне місце проживання (зберігання зброї)',
  birthAndRegistrationAddress = 'Місце народження та реєстрації',
  purposeAndExtractType = 'Мета запиту і тип витягу',
  payment = 'Оплата',
  additionalDocument = 'Дані про додатковий документ',
  confirmationOfApplication = 'Підтвердження даних заяви',
  directionOfTransportation = 'Дані про напрямок перевезення',
  transportAndRoute = 'Дані про транспорт та маршрут',
  technicalCondition = 'Документ про огляд технічного стану та відстріл',
  insurance = 'Дані про страхування',
  newAddressOfResidence = 'Нова адреса місця проживання (зберігання зброї)',
  objectOfPurchase = 'Вибір предмету придбання',
  personStatusTraumaticDevices = 'Статус особи (для придбання пристроїв травматичної дії)',
  personStatus = 'Статус особи',
  materialPartsOfWeapons = 'Дані про вивчення матеріальної частини зброї, правил поводження та застосування (крім охолощеної та газової зброї)',
  health = 'Дані про стан здоров’я',
  personalMissingPerson = 'Особисті дані зниклої особи',
  addressMissingPerson = 'Адреса реєстрації та проживання зниклої особи',
  informationDisappearance = 'Інформація про зникнення',
  informationCriminalProceedings = 'Відомості про кримінальне провадження',
  informationApplicant = 'Відомості про заявника',
  confirmationOfQuestionnaire = 'Підтвердження даних анкети',
  signUp = 'Підпис ЕЦП',
  result = 'Результат',
}
