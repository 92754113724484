import { ArtifactType } from '../components/Api/types';
import { FileType } from './fileHelper';

const ArtifactFileTypeMapping: Record<ArtifactType, FileType> = {
  [ArtifactType.EXTRACT]: FileType.PDF,
  [ArtifactType.RECEIPT]: FileType.PDF,
  [ArtifactType.SIGN]: FileType.SIGNATURE,
  [ArtifactType.MESSAGE]: FileType.PDF,
  [ArtifactType.APPLICATION]: FileType.PDF,
};

export const createArtifactRequestHeaders = (artifactType: ArtifactType): Headers => {
  const headers = new Headers();
  headers.set('Accept', ArtifactFileTypeMapping[artifactType]);
  headers.set('Content-Type', 'application/octet-stream');

  return headers;
};
