import React, { useContext, useState, MouseEvent } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import './HeaderBanner.scss';

import { OrderServiceLink } from './OrderServiceLink';
import AuthContext from '../../../contexts/Auth/AuthContext';
import logoImg from '../../../assets/images/logo.svg';
import userIcon from '../../../assets/images/user-big.svg';
import exitIcon from '../../../assets/images/exit.svg';
import { GovUaToast } from '../../Toasts/GovUaToast';
import { AccessibilityPanelButton } from '../AccessibilityPanel/AccessibilityPanelButton';
import { MVSButton } from './MVSButton';

const HeaderBanner = (): JSX.Element => {
  const authContext = useContext(AuthContext);
  const { pathname } = useLocation();
  const [isGovUaToastShown, setGovUaToastShown] = useState(false);

  const govUaToastHandler = (e: MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    if (isGovUaToastShown) {
      setGovUaToastShown(false);
      return;
    }
    setGovUaToastShown(true);
  };

  return (
    <div className="header-banner">
      <div className="container">
        <div className="header-banner__content">
          <div className="header-banner__links">
            <GovUaToast govUaToastHandler={govUaToastHandler} isGovUaToastShown={isGovUaToastShown} variant="large" />
            <MVSButton />
          </div>
          <NavLink to="/" className="header-banner__logo">
            <img className="header-banner__logo-img" src={logoImg} alt="logo" width="72" height="72" />
            <h1 className="header-banner__logo-title">Єдине вікно для громадян</h1>
          </NavLink>
          <div className="header-banner__action-buttons">
            {authContext.isLoggedIn && (
              <div className="media-title__title">
                <Link
                  to="/userarea/user/dashboard"
                  className="media-title header-banner__media-title"
                  style={{ display: 'flex' }}
                >
                  <img className="media-title__media" src={userIcon} alt="user" width="24" height="24" />
                  <span className="media-title__title">Особистий кабінет</span>
                </Link>
              </div>
            )}
            {!authContext.isLoggedIn && (
              <div className="media-title header-banner__media-title header-banner__media-title_margin-bottom header-banner__media-title_default-hover">
                <NavLink to="/login" className="media-title header-banner__media-title">
                  <img className="media-title__media" src={userIcon} alt="user" width="24" height="24" />
                  <span className="media-title__title">Увійти</span>
                </NavLink>
              </div>
            )}
            {/* {authContext.isLoggedIn && (
                <NavLink
                  to="/admin"
                  className="media-title header-banner__media-title"
                >
                  <img
                    className="media-title__media"
                    src={userIcon}
                    alt="user"
                    width="24"
                    height="24"
                  />
                  <span className="media-title__title">Адмін панель</span>
                </NavLink>
              )} */}
            {authContext.isLoggedIn && (
              <div className="media-title__title">
                <Link
                  to="/login"
                  state={{
                    nextPage: pathname,
                  }}
                  className="media-title header-banner__media-title"
                  onClick={(): void => authContext.logout()}
                >
                  <img className="media-title__media" src={exitIcon} alt="user" width="24" height="24" />
                  <span className="media-title__title">Вийти</span>
                </Link>
              </div>
            )}

            {/* {!ctx.isLoggedIn && (   )}  */}
            {/* {!ctx.isLoggedIn && (   )} */}
            {/* {!ctx.isLoggedIn && (   )} */}
            {/* {!ctx.isLoggedIn && (   )} */}

            {/* <NavLink to="/login" className="media-title header-banner__media-title"> */}
            {/*     <img className="media-title__media" src={userIcon} alt=""/> */}
            {/*     <span className="media-title__title">Кабінет</span> */}
            {/* </NavLink> */}
            {/* <NavLink to="/login" className="media-title header-banner__media-title"> */}
            {/*     <img className="media-title__media" src={userIcon} alt=""/> */}
            {/*     <span className="media-title__title">Razors Brothers</span> */}
            {/* </NavLink> */}
            {/* <NavLink to="/login" className="media-title header-banner__media-title"> */}
            {/*     <img className="media-title__media" src={userIcon} alt=""/> */}
            {/*     <span className="media-title__title">Адмін панель</span> */}
            {/* </NavLink> */}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <AccessibilityPanelButton />
          </div>
        </div>
      </div>

      <div className="header-banner__additional-row">
        <div className="container header-banner__additional-row__container">
          <OrderServiceLink />
        </div>
      </div>
    </div>
  );
};

export default HeaderBanner;
