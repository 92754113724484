import React from 'react';

import './DefaultLink.scss';

interface Props {
  className: string;
  link: string;
  title: string;
}

const DefaultLink = ({ title, className, link }: Props): JSX.Element => {
  return (
    <a className={className} href={link}>
      {title}
    </a>
  );
};

export default DefaultLink;
