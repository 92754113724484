import isEmpty from 'lodash/isEmpty';

import { api } from '../components/Api';
import { DocumentType, IntegrationSystem } from '../components/Api/types';
import { SelectOption } from '../components/Forms/Select';

export interface DocumentTypeEntity<T extends IntegrationSystem = never> extends SelectOption {
  code: DocumentType<T>['code'] | 'null';
}

export interface GetERZDocumentsSelectOptions {
  allowedDocumentTypeCodes?: [DocumentType<IntegrationSystem.ERZ>['code']];
  disallowedDocumentTypeKinds?: Array<DocumentType<IntegrationSystem.ERZ>['kind']>;
  setOptions?: (newOptions: Array<DocumentTypeEntity<IntegrationSystem.ERZ>>) => void;
}

function reformatToEntity<T extends IntegrationSystem>(documentType: DocumentType<T>): DocumentTypeEntity<T> {
  return {
    id: documentType.id,
    label: documentType.name,
    code: documentType.code || 'null',
  };
}

export const getERZDocumentTypeEntites = async (
  allowedDocumentTypeCodes: Array<DocumentType<IntegrationSystem.ERZ>['code']> = [],
  disallowedDocumentTypeKinds: Array<DocumentType<IntegrationSystem.ERZ>['kind']> = [],
): Promise<Array<DocumentTypeEntity<IntegrationSystem.ERZ>>> => {
  let documentTypes = (await api.dictionary.getDocumentTypes.erz()).data;

  if (!!allowedDocumentTypeCodes?.length) {
    documentTypes = allowedDocumentTypeCodes.reduce((accumulator, documentTypeCode) => {
      const wantedDocumentType = documentTypes.find(
        (documentType) => documentType.code !== null && documentType.code === documentTypeCode,
      );

      return isEmpty(wantedDocumentType) ? accumulator : [...accumulator, wantedDocumentType];
    }, []);
  }

  if (!!disallowedDocumentTypeKinds?.length) {
    documentTypes = documentTypes.filter((documentType) => !disallowedDocumentTypeKinds.includes(documentType.kind));
  }

  const entities = documentTypes.map(reformatToEntity);

  return entities;
};

export const getIACDocumentTypeEntites = async (): Promise<Array<DocumentTypeEntity<IntegrationSystem.IAC>>> => {
  const documentTypes = (await api.dictionary.getDocumentTypes.iac()).data;

  const entities = documentTypes.map(reformatToEntity);

  return entities;
};

export const getERZDocumentsSelect = async ({
  allowedDocumentTypeCodes,
  disallowedDocumentTypeKinds = [],
  setOptions,
}: GetERZDocumentsSelectOptions = {}): Promise<Array<DocumentTypeEntity<IntegrationSystem.ERZ>>> => {
  const entities = await getERZDocumentTypeEntites(allowedDocumentTypeCodes, disallowedDocumentTypeKinds);
  setOptions && setOptions(entities);

  return entities;
};
