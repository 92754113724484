export const saveFileFromBlob = (filename: string, data: Blob): void => {
  const binaryData = [data];
  const url = window.URL.createObjectURL(new Blob(binaryData));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
};
