export enum FileType {
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  JPG = 'image/jpg',
  PDF = 'application/pdf',
  SIGNATURE = 'application/pkcs7-signature',
}

// TODO: learn how to get this value from response headers
export enum FileFormat {
  PNG = '.png',
  JPEG = '.jpeg',
  JPG = '.jpg',
  PDF = '.pdf',
  SIGNATURE = '.p7s',
}

export const base64ToPdfBlob = (input: string): Blob => {
  const binaryString = atob(input);

  const binaryData = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    binaryData[i] = binaryString.charCodeAt(i);
  }

  return new Blob([binaryData], { type: FileType.PDF });
};

export const fileToBase64 = async (files: File[]): Promise<string[]> => {
  const arrFiles: string[] = [];

  for (let i = 0; i < files.length; i++) {
    const reader = new FileReader();
    const filePromise = new Promise<string>((resolve) => {
      reader.onload = function () {
        const base64String = btoa(reader.result as string);
        resolve(base64String.toString());
      };
    });
    reader.readAsBinaryString(files[i]);
    const base64 = await filePromise;
    arrFiles.push(base64);
  }

  return arrFiles;
};

export async function base64ToFile(base64: string, fileName = 'fileName', fileType = FileType.PNG): Promise<File> {
  const res: Response = await fetch(`data:${fileType};base64,` + base64);
  const blob: Blob = await res.blob();

  return new File([blob], fileName, { type: fileType });
}
