import React, { PropsWithChildren, useMemo } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import classNames from 'classnames';
import './Button.scss';

import { ExternalLink } from '../Forms/ExternalLink/ExternalLink';

type LinkAsButtonProps = {
  isExternal?: boolean;
  title?: string;
  outline?: boolean;
  size?: 'sm' | 'md' | 'lg';
  bgColor?: 'blue' | 'error';
  withIcon?: boolean;
  className?: string;
  isAnchor?: boolean;
  onClick?: () => void;
} & LinkProps &
  PropsWithChildren;

export const LinkAsButton: React.FC<LinkAsButtonProps> = ({
  to,
  isExternal = false,
  title = '',
  outline = false,
  size = 'lg',
  bgColor = 'blue',
  withIcon = false,
  className = '',
  isAnchor = false,
  children,
  onClick,
  ...linkProps
}) => {
  const classList = useMemo(() => {
    return classNames(
      'link-as-button',
      {
        'link-as-button__outline': outline,
        [`link-as-button__${size}`]: size,
        'link-as-button__with-icon': withIcon,
        [`link-as-button__${bgColor}`]: bgColor,
      },
      className,
    );
  }, [bgColor, className, withIcon, outline, size]);

  const inner = useMemo(() => {
    return children || <span className="align-text-top">{title}</span>;
  }, [children, title]);

  return isExternal ? (
    <ExternalLink {...linkProps} href={to as string} className={classList} onClick={onClick}>
      {inner}
    </ExternalLink>
  ) : (
    <Link {...linkProps} to={to} className={classList} preventScrollReset={isAnchor} aria-label={title} role="button">
      {inner}
    </Link>
  );
};
