import React from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import { ServiceRoutes } from '../config/services';

export const GercRedirectMiddleware = (): JSX.Element => {
  const queryParams = useSearchParams()[0];
  const requestId = queryParams.get('requestId');
  const serviceCode = queryParams.get('serviceCode');

  return (
    <Navigate to={ServiceRoutes[serviceCode] || '/'} state={{ continueGercPayment: true, request_id: +requestId }} />
  );
};
