import { api } from '../Api';
import { SelectOption } from '../Forms/Select';
import { DataResponse, ResponseAccordionText, ResponseStoredLinks } from './types';

type SetOptions = (newOptions: SelectOption[]) => void;

const handleEmptyResponse = <T>(response: DataResponse<T>): T => {
  const { data: responseContent = [] as T } = response;

  return responseContent;
};

export const getCountrySelect = async (option?: SetOptions): Promise<SelectOption[]> => {
  const countries = handleEmptyResponse(await api.dictionary.getCountry());
  const newOptions = countries.map<SelectOption>(
    (country) =>
      ({
        id: country.id,
        label: country.shortName,
      }) satisfies SelectOption,
  );

  option && option(newOptions);

  return newOptions;
};

export const getRegionSelect = async (option: SetOptions): Promise<void> => {
  const regions = handleEmptyResponse(await api.dictionary.getRegion());
  const newOptions = regions.map<SelectOption>(
    (region) =>
      ({
        id: region.id,
        label: region.name,
      }) satisfies SelectOption,
  );

  option(newOptions);
};

export const getDistrictSelect = async (id: string, option: SetOptions): Promise<void> => {
  const districts = handleEmptyResponse(await api.dictionary.getDistrict(id));
  const newOptions = districts.map<SelectOption>(
    (district) =>
      ({
        id: district.id,
        label: district.name,
      }) satisfies SelectOption,
  );

  option(newOptions);
};

export const getCitySelect = async (id: string, option: SetOptions): Promise<void> => {
  const cities = handleEmptyResponse(await api.dictionary.getCity(id));
  const newOptions = cities.map<SelectOption>(
    (city) =>
      ({
        id: city.id,
        label: city.name,
      }) satisfies SelectOption,
  );

  option(newOptions);
};

export const getStreetSelect = async (id: string, option: SetOptions): Promise<void> => {
  const streets = handleEmptyResponse(await api.dictionary.getStreet(id));
  const newOptions = streets.map<SelectOption>(
    (street) =>
      ({
        id: street.id,
        label: (street.subType?.abbr || '') + ' ' + street.name,
      }) satisfies SelectOption,
  );

  option(newOptions);
};

export const getOrgNpuSelect = async (option: SetOptions): Promise<void> => {
  const authorities = handleEmptyResponse(await api.dictionary.getAuthorities());
  const newOptions = authorities.map<SelectOption>(
    (authority) =>
      ({
        id: authority.id,
        label: authority.name,
      }) satisfies SelectOption,
  );

  option(newOptions);
};

export const getIndividualStatusSelect = async (option: SetOptions): Promise<void> => {
  const individualStatuses = handleEmptyResponse(await api.dictionary.getIndividualStatus());
  const newOptions = individualStatuses.map<SelectOption>(
    (individualStatus) =>
      ({
        id: individualStatus.id,
        label: individualStatus.name,
      }) satisfies SelectOption,
  );

  option(newOptions);
};

export const getStoredLinks = async (): Promise<ResponseStoredLinks> => {
  try {
    const response = await fetch('/links.json');

    if (!response.ok) return;

    return await response.json();
  } catch (error) {
    return;
  }
};

export const getAccordionsText = async (): Promise<ResponseAccordionText> => {
  try {
    const response = await fetch('/accordionsText.json');

    if (!response.ok) return;

    return await response.json();
  } catch (error) {
    return;
  }
};
