import React, { useEffect } from 'react';

import { useCaptcha, Callback } from './useCaptcha';
import { config } from '../../config';

type Props = {
  onSubmit: Callback;
};

export const Captcha: React.FC<Props> = ({ onSubmit }) => {
  const { aliasExpired, aliasSubmit } = useCaptcha(onSubmit);

  return (
    <div
      className="g-recaptcha"
      data-sitekey={config.captcha.siteKey}
      data-callback={aliasSubmit}
      data-expired-callback={aliasExpired}
      data-size="normal"
    />
  );
};

export const MetaCaptcha: React.FC<Props> = ({ onSubmit }) => {
  useEffect(() => {
    if (!config.captcha.enabled) onSubmit(true);
  }, []);

  if (!config.captcha.enabled) {
    return <template className="captcha" />;
  }

  return <Captcha onSubmit={onSubmit} />;
};
