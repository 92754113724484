import EventBus from 'js-event-bus';

const eventBus = new EventBus();

export type EventLogin = {
  action: 'login';
};

export type EventLogout = {
  action: 'logout';
};

export type EventAntiFloodException = {
  action: 'anti_flood_exception';
  timeout: number;
};

export type EventUpdateData = {
  action: 'update_profile' | 'update_features';
};

type Events = EventLogin | EventLogout | EventAntiFloodException | EventUpdateData;

export const emit = ({ action, ...context }: Events): void => {
  eventBus.emit(action, context);
};

export const on = <Event extends Events>(event: Event['action'], cb: (e: Event) => Promise<void> | void): void => {
  eventBus.on(event, function () {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    cb(this);
  });
};

export const die = <Event extends Events>(event: Event['action']): void => {
  eventBus.die(event);
};
