import React, { useState, useEffect } from 'react';

import pdf from '../../assets/images/pdf-icon.svg';

export const PreviewFilesList: React.FC<{ files: File[] }> = ({ files }) => {
  const [imageSrcs, setImageSrcs] = useState<string[]>([]);

  const handleFileChosen = (file: File) => {
    return new Promise<{ dataURL: string }>((resolve) => {
      const reader = new FileReader();
      reader.onload = () => resolve({ dataURL: reader.result as string });
      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    Promise.all(files.map((file) => handleFileChosen(file)))
      .then((results) => {
        const newImageSrcs = results.map(({ dataURL }) => dataURL);
        setImageSrcs(newImageSrcs);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }, [files]);

  return (
    <>
      {files.map((file, index) => (
        <img
          key={file.name + +index}
          src={imageSrcs[index]}
          alt={file.name}
          onError={(event) => {
            event.currentTarget.setAttribute('src', pdf);
          }}
        />
      ))}
    </>
  );
};
