import React, { memo, useContext } from 'react';

import ModalContext from '../../../contexts/Modal/ModalContext';
import { ModalBorderTopColor, ModalConstructor } from '../ModalConstructor';
import './DeleteServiceDraftAlert.scss';
import { useFormDraft } from '../../../hooks/useFormDraft';

export interface DeleteServiceDraftAlertProps {
  draftIdToDelete: number;
  onDeleteCallback: () => void;
}

export const DeleteServiceDraftAlert = memo(function DeleteServiceDraftAlert({
  draftIdToDelete,
  onDeleteCallback,
}: DeleteServiceDraftAlertProps) {
  const { toggleModal } = useContext(ModalContext);
  const { deleteDraft } = useFormDraft();

  return (
    <ModalConstructor modalBorderTopColor={ModalBorderTopColor.red}>
      <div className="delete-service-alert">
        <div className="delete-service-alert__title">
          <div className="block-title_md">Дану чернетку буде видалено</div>
          <span>Ви впевнені, що бажаєте видалити дану чернетку?</span>
        </div>

        <div className="delete-service-alert__buttons-box">
          <button
            className="button button_border button_width-max"
            type="button"
            onClick={() => {
              toggleModal(false);
            }}
          >
            Скасувати
          </button>
          <button
            className="button button_danger button_width-max"
            type="button"
            onClick={() => {
              deleteDraft(draftIdToDelete);
              onDeleteCallback();
              toggleModal(false);
            }}
          >
            Видалити
          </button>
        </div>
      </div>
    </ModalConstructor>
  );
});
