/* eslint-disable no-console */
import { isEmpty } from 'lodash';

import { formatCacheDate, getDateWithoutTime } from './helpers/dates';
import { config } from './config';

const todaysDate = getDateWithoutTime(new Date());

type Endpoint = string;
type EndpointResponse = string;
type CacheValue = {
  data: EndpointResponse;
  meta: {
    app_version: string;
    date_of_cache: string;
  };
};
type CacheState = Record<Endpoint, CacheValue>;
type SetCache = (props: { endpoint: Endpoint; endpointResponse: EndpointResponse }) => void;
type IsCacheEmpty = (endpoint: Endpoint) => boolean;

const localStorageController = {
  keyNamePrefix: '/',
  get cachedEndpointKeyNames(): string[] {
    return Object.keys(window.localStorage).filter((localStorageKey) => localStorageKey.startsWith(this.keyNamePrefix));
  },
  get cachedData(): CacheState | null {
    return this.cachedEndpointKeyNames.reduce((cachedData, cachedEndpointKeyName) => {
      try {
        const cacheValue = JSON.parse(window.localStorage.getItem(cachedEndpointKeyName));
        cachedData[cachedEndpointKeyName] = cacheValue;
      } catch (error) {
        console.error(`localStorage.${cachedEndpointKeyName} is not parsable\n`, 'Value is set to null\n', error);
        this.clearCachedEndpoint(cachedEndpointKeyName);
      } finally {
        return cachedData;
      }
    }, {} as CacheState);
  },
  setCachedData(data: CacheState) {
    const [endpoint, cacheValue] = Object.entries(data)[0];
    window.localStorage.setItem(endpoint, JSON.stringify(cacheValue));
  },
  clearCachedData(): void {
    localStorageController.cachedEndpointKeyNames.forEach((cachedEndpointKeyName) =>
      window.localStorage.removeItem(cachedEndpointKeyName),
    );
    console.info(`Deleted all cached data`);
  },
  clearCachedEndpoint(endpoint: Endpoint): void {
    window.localStorage.removeItem(endpoint);
    console.info(`Deleted cache with ${endpoint} key`);
  },
};

export class CacheTool {
  private _cache: CacheState = null;

  constructor() {
    const { validCache, invalidCache } = this.filterInvalidCache(localStorageController.cachedData || {});

    this.cache = validCache;
    this.deleteInvalidCache(Object.keys(invalidCache));

    // @ts-ignore
    window.clearCache = localStorageController.clearCachedData;
    // @ts-ignore
    window.clearCachedEndpoint = localStorageController.clearCachedEndpoint;
  }

  public get cache(): CacheState {
    return this._cache;
  }
  private set cache(data: CacheState) {
    this._cache = data;
  }

  public setCache: SetCache = ({ endpoint, endpointResponse }) => {
    const newEndpointCache = {
      [endpoint]: {
        data: endpointResponse,
        meta: {
          app_version: config.VERSION,
          date_of_cache: formatCacheDate(new Date()),
        },
      },
    };

    this.cache = {
      ...this._cache,
      ...newEndpointCache,
    };
    localStorageController.setCachedData(newEndpointCache);
  };

  private isCacheEmpty: IsCacheEmpty = (endpoint) => isEmpty(this.cache?.[endpoint]);

  private isCacheRelevant = (endpoint: Endpoint, cache = this.cache): boolean => {
    try {
      const { meta } = cache[endpoint];

      const dateOfCache = getDateWithoutTime(meta.date_of_cache);
      const validFromDate = getDateWithoutTime(config.cache.validFrom);
      const lastRelevantDate = new Date(dateOfCache.getTime());
      lastRelevantDate.setDate(dateOfCache.getDate() + config.cache.validityPeriod);

      const isVersionRelevant = meta.app_version === config.VERSION;
      const isDateRelevant = dateOfCache >= validFromDate && lastRelevantDate >= todaysDate;

      return isVersionRelevant && isDateRelevant;
    } catch (error) {
      console.group('isCacheRelevant');
      console.log({
        endpoint,
        value: cache[endpoint],
      });
      console.error(error);
      console.groupEnd();

      return false;
    }
  };

  public hasToBeCached = (endpoint: Endpoint): boolean =>
    this.isCacheEmpty(endpoint) || !this.isCacheRelevant(endpoint);

  private filterInvalidCache = (cache: CacheState): Record<'validCache' | 'invalidCache', CacheState> => {
    return Object.entries(cache).reduce(
      (cacheState, currentCache) => {
        const [endpoint, cacheValue] = currentCache;

        if (this.isCacheRelevant(endpoint, cache)) {
          return {
            validCache: {
              ...cacheState.validCache,
              [endpoint]: cacheValue,
            },
            invalidCache: { ...cacheState.invalidCache },
          };
        }

        console.info(`Detected invalid cache with key ${endpoint}`);

        return {
          validCache: { ...cacheState.validCache },
          invalidCache: {
            ...cacheState.invalidCache,
            [endpoint]: cacheValue,
          },
        };
      },
      {
        validCache: {},
        invalidCache: {},
      } as ReturnType<typeof this.filterInvalidCache>,
    );
  };

  private deleteInvalidCache = (cacheKeys: string[]): void => {
    cacheKeys.forEach(localStorageController.clearCachedEndpoint);
  };
}
