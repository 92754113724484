import React from 'react';

interface Props extends React.HTMLProps<HTMLAnchorElement> {
  href: string;
  currTab?: boolean;
  className?: string;
  children: React.ReactNode;
  onClick?: () => void;
}

export const ExternalLink = React.memo<Props>(function Links({
  href,
  currTab = false,
  className = '',
  children,
  onClick,
  ...linkProps
}) {
  return (
    <a
      {...linkProps}
      href={href}
      className={className}
      rel="noopener noreferrer"
      target={currTab ? '_self' : '_blank'}
      onClick={onClick}
    >
      {children}
    </a>
  );
});
