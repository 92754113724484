import React from 'react';
import classNames from 'classnames';

import { Button, LinkAsButton } from '../Button';
import SaveIcon from '../../assets/images/save_icon_blue.svg';
import Loader from '../Loader';

export enum FormStepButtonId {
  dashboard = 'dashboard-button',
  next = 'next-button',
  previous = 'previous-buton',
  approve_and_download = 'approve-and-download-button',
  sign = 'sign-button',
  pay = 'pay-button',
  download_file = 'download-file-button',
  home = 'hone-button',
}

export const PreviousStepButton: React.FC<{
  handlePrevStep: () => void;
}> = ({ handlePrevStep }) => (
  <Button
    id={FormStepButtonId.previous}
    className="form__button form__button--left-side"
    outline
    withIcon
    onClick={handlePrevStep}
  >
    <div>
      <svg
        className="button__img_rotated"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z" fill="#1e4dba" />
      </svg>
    </div>
    <div>Назад</div>
  </Button>
);
export const NextStepButton: React.FC<{
  disabled?: boolean;
  handleNextStep: () => void;
}> = ({ disabled = false, handleNextStep }) => (
  <Button
    id={FormStepButtonId.next}
    className="form__button form__button--right-side"
    disabled={disabled}
    withIcon
    onClick={handleNextStep}
  >
    <div>Далі</div>
    <div>
      <svg
        className="button__img"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z" fill="white"></path>
      </svg>
    </div>
  </Button>
);

export const ApproveAndDownloadButton: React.FC<{
  disabled: boolean;
  onClick: () => void;
}> = ({ disabled, onClick }) => (
  <Button
    id={FormStepButtonId.approve_and_download}
    className="form__button form__button--right-side"
    withIcon
    onClick={onClick}
    disabled={disabled}
  >
    <div>Підтвердити&nbsp;і завантажити</div>
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z" fill="white"></path>
      </svg>
    </div>
  </Button>
);

export const SignButton: React.FC<{
  disabled: boolean;
  onClick: () => void;
}> = ({ onClick, disabled }) => (
  <Button
    id={FormStepButtonId.sign}
    className="form__button form__button--right-side"
    disabled={disabled}
    onClick={onClick}
  >
    Підписати
  </Button>
);

export const HomeButton: React.FC = () => (
  <LinkAsButton id={FormStepButtonId.home} to="/" className="form__button form__button--right-side">
    На головну
  </LinkAsButton>
);

export const DownloadButton: React.FC<{
  text: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  downloading?: boolean;
  place?: 'left-side' | 'centered';
  outline?: boolean;
  disabled?: boolean;
  withStyles?: boolean;
  className?: string;
}> = ({
  text,
  onClick,
  downloading,
  place = 'left-side',
  disabled = false,
  outline = false,
  withStyles = true,
  className = '',
}) => (
  <Button
    id={FormStepButtonId.download_file}
    className={classNames(className, {
      [`form__button form__button--${place}`]: withStyles,
    })}
    onClick={onClick}
    outline={outline}
    disabled={disabled}
    withIcon
  >
    {text}
    <span
      style={{
        position: 'relative',
        minHeight: '24px',
        minWidth: '24px',
      }}
    >
      {downloading ? <Loader centered /> : <img src={SaveIcon} alt="save file" />}
    </span>
  </Button>
);

export const PayButton: React.FC<{
  disabled?: boolean;
  onClick: () => void;
}> = ({ disabled = false, onClick }) => (
  <Button
    id={FormStepButtonId.pay}
    className="form__button form__button--right-side"
    disabled={disabled}
    onClick={onClick}
    withIcon
    size="lg"
  >
    <div>Перейти до оплати</div>
    <div>
      <svg
        className="button__img"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z" fill="white"></path>
      </svg>
    </div>
  </Button>
);

export const GoToPaymentStepButton: React.FC<{
  disabled?: boolean;
  onClick: () => void;
}> = ({ onClick, disabled = false }) => (
  <Button
    id={FormStepButtonId.sign}
    className="form__button form__button--left-side"
    disabled={disabled}
    onClick={onClick}
    outline
  >
    Оплатити
  </Button>
);

export const PayLaterButton: React.FC<{
  disabled?: boolean;
  onClick: () => void;
}> = ({ onClick, disabled = false }) => (
  <Button
    id={FormStepButtonId.sign}
    className="form__button form__button--left-side"
    disabled={disabled}
    onClick={onClick}
    outline
  >
    Оплатити пізніше
  </Button>
);

export const DashboardButton: React.FC<{
  outline?: boolean;
}> = ({ outline = false }) => (
  <LinkAsButton
    id={FormStepButtonId.dashboard}
    to="/userarea/user/dashboard/active-requests"
    className="form__button form__button--left-side"
    outline={outline}
  >
    На дашборд
  </LinkAsButton>
);
