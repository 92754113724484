export enum AUTH {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
}

interface State {
  isLoggedIn: boolean;
}

interface Action {
  type: AUTH;
  payload?: boolean;
}

const authReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case AUTH.LOGIN:
      return {
        isLoggedIn: true,
      };
    case AUTH.LOGOUT:
      return {
        isLoggedIn: false,
      };
    default:
      return state;
  }
};

export { authReducer };
