import { emit } from '../../eventBus';

export const antiFloodMiddleware = async (response: Response): Promise<Response> => {
  if (response.ok) return response;

  if (response.status === 429) {
    emit({
      action: 'anti_flood_exception',
      // TODO: get x-ratelimit-limit from response headers
      timeout: 60,
    });
  } else {
    const error = {
      message: await response.text(),
      responseStatus: response.status,
    };

    throw new Error(JSON.stringify(error)).message;
  }
};
