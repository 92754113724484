import { useContext } from 'react';

import MVSLogo from '../../../assets/images/admin-mvs-logo.svg';
import AuthContext from '../../../contexts/Auth/AuthContext';

export const MVSButton: React.FC = () => {
  const { storedLinks } = useContext(AuthContext);

  return (
    <div className="media-title__title">
      <a
        href={storedLinks.externalLink?.mvsWebsite}
        className="media-title header-banner__media-title"
        target="_blank"
        rel="noreferrer"
      >
        <img className="media-title__media" src={MVSLogo} alt="eye" width="24" height="24" />
        <span className="media-title__title">Міністерство внутрішніх справ України</span>
      </a>
    </div>
  );
};
