import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import './SearchForm.scss';

import MainTemplate from '../../../templates/MainTemplate/MainTemplate';
import searchIcon from '../../../assets/images/search-grey.svg';
import SadSmileIcon from '../../../assets/images/sad_smile.svg';
import Loader from '../../Loader';
import { MetaCaptcha } from '../../Captcha';
import { HeaderWithHomeLinkButton } from '../../Forms/HeaderWithHomeLinkButton';
import { Button } from '../../Button';

export interface SearchFormProps<R = unknown> extends React.PropsWithChildren {
  title: React.ReactNode;
  result: R;
  isLoading: boolean;
  canSubmit: boolean;
  qrSearch?: boolean;
  headerText?: string;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
  renderResult: (result: R) => React.ReactNode;
}

export const SearchFormTemplate = <R,>({
  children,
  title,
  result,
  isLoading,
  canSubmit,
  qrSearch = false,
  headerText = '',
  onSubmit,
  renderResult,
}: SearchFormProps<R>): JSX.Element => {
  const [isSearchHappened, setIsSearchHappened] = useState<boolean>(false);
  const [isCaptchaValid, setCaptchaValid] = useState<boolean>(false);

  return (
    <MainTemplate>
      <section className="section-light search-form">
        <div className="container">
          <div className="block-title_margin-bottom-md">
            <HeaderWithHomeLinkButton header={title} />
          </div>

          <div className="search-form__content">
            <div className="search-form__content__search-block">
              {headerText && (
                <h2 className="search-form__content__search-block__title block-title block-title block-title_md block-title_margin-bottom-sm-xl">
                  {headerText}
                </h2>
              )}
              <form className="search-form__content__search-block__form">
                {children}

                <MetaCaptcha onSubmit={setCaptchaValid} />

                <div className="search-form__content__search-block__form__footer">
                  <div className="separator" />
                  <Button
                    id="submit-search-button"
                    className="button button_primary-dark button_width-sm button_margin-left-auto"
                    disabled={!canSubmit || !isCaptchaValid || isLoading}
                    onClick={(e): void => {
                      setIsSearchHappened(true);
                      isCaptchaValid && onSubmit(e as any);
                    }}
                  >
                    Шукати
                  </Button>
                </div>
              </form>
            </div>

            <div className="search-form__content__result-block">
              {isLoading && <Loader centered />}
              {!isSearchHappened && !isLoading && isEmpty(result) && HintHTML}
              {isSearchHappened && !isLoading && isEmpty(result) && EmptyResultHTML}
              {!isLoading && !isEmpty(result) && renderResult(result)}
            </div>
          </div>
        </div>
      </section>
    </MainTemplate>
  );
};

const HintHTML = (
  <div className="information">
    <div>
      <img src={searchIcon} alt="search icon" />
    </div>
    <div>
      <div>Скористайтесь пошуком.</div>
      <div>Тут відображатимуться його результати</div>
    </div>
  </div>
);

const EmptyResultHTML = (
  <div className="information">
    <div>
      <img src={SadSmileIcon} alt="sad smile" />
    </div>
    <div>
      <div>За вашим запитом не знайдено жодного результату</div>
      <div>Перевірте правильність введених даних та спробуйте ще раз</div>
    </div>
  </div>
);
