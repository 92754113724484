import React, { useContext } from 'react';

import AccessibilityContext from '../../../contexts/AccessibilityContext';
import contrast from '../../../assets/images/contrast.svg';
import add from '../../../assets/images/add.svg';
import minus from '../../../assets/images/minus.svg';
import backup from '../../../assets/images/backup.svg';
import close from '../../../assets/images/close.svg';
import './AccessibilityPanel.scss';

interface Props {
  isPanelShown: boolean;
}

const AccessibilityPanel = ({ isPanelShown }: Props): JSX.Element => {
  const { turnOnAccessibilityPanel } = useContext(AccessibilityContext);
  const tagHtml = document.getElementsByTagName('html');
  const classOpenedPanel = isPanelShown ? 'accessibility-panel-opened' : {};
  const sectionAccessibilityClasses = 'section-accessibility-panel ' + classOpenedPanel;
  const styles = isPanelShown
    ? {
        display: 'block',
        height: 'auto',
      }
    : {
        display: 'block',
        height: '0',
      };

  const plusHandler = () => {
    if (!tagHtml[0].classList.contains('accessibility-size')) {
      tagHtml[0].classList.add('accessibility-size', 'large-1');
      localStorage.setItem('accessibilitySize', 'large-1');
    } else if (tagHtml[0].classList.contains('large-1')) {
      tagHtml[0].classList.remove('large-1');
      tagHtml[0].classList.add('large-2');
      localStorage.setItem('accessibilitySize', 'large-2');
    } else if (tagHtml[0].classList.contains('large-2')) {
      tagHtml[0].classList.remove('large-2');
      tagHtml[0].classList.add('large-3');
      localStorage.setItem('accessibilitySize', 'large-3');
    }
  };

  const minusHandler = () => {
    if (tagHtml[0].classList.contains('large-1')) {
      tagHtml[0].classList.remove('accessibility-size', 'large-1');
      localStorage.removeItem('accessibilitySize');
    } else if (tagHtml[0].classList.contains('large-2')) {
      tagHtml[0].classList.remove('large-2');
      tagHtml[0].classList.add('large-1');
      localStorage.setItem('accessibilitySize', 'large-1');
    } else if (tagHtml[0].classList.contains('large-3')) {
      tagHtml[0].classList.remove('large-3');
      tagHtml[0].classList.add('large-2');
      localStorage.setItem('accessibilitySize', 'large-2');
    }
  };

  if (localStorage.getItem('accessibilityContrast')) {
    tagHtml[0].classList.add('contrastColors');
  }

  const actionContrastButton = () => {
    if (tagHtml[0].classList.contains('contrastColors')) {
      tagHtml[0].classList.remove('contrastColors');
      localStorage.removeItem('accessibilityContrast');
    } else {
      tagHtml[0].classList.add('contrastColors');
      localStorage.setItem('accessibilityContrast', 'true');
    }
  };

  const accessibilityResetButton = () => {
    if (tagHtml[0].classList.contains('contrastColors')) {
      tagHtml[0].classList.remove('contrastColors');
      localStorage.removeItem('accessibilityContrast');
    }
    tagHtml[0].classList.remove('accessibility-size', 'large-1', 'large-2', 'large-3');
    localStorage.removeItem('accessibilitySize');
  };

  if (localStorage.getItem('accessibilitySize')) {
    tagHtml[0].classList.add('accessibility-size', localStorage.getItem('accessibilitySize') ?? '');
  }

  return (
    <div className={sectionAccessibilityClasses} style={styles}>
      <div className="container">
        <div className="section-accessibility-panel-box">
          <div className="accessibility-panel-settings-buttons">
            <div className="accessibility-panel-settings__filter">
              <div className="accessibility-panel-settings__title">Контраст</div>
              <button
                onClick={actionContrastButton}
                className="accessibility-panel-settings__filter-icon-box action-contrast-btn"
              >
                <img
                  className="accessibility-panel-settings__filter-icon"
                  src={contrast}
                  alt="contrast"
                  width="18"
                  height="18"
                />
              </button>
            </div>
            <div className="accessibility-panel-settings__size">
              <div className="accessibility-panel-settings__title">Шрифт</div>
              <button
                onClick={plusHandler}
                className="accessibility-panel-settings__icon-box accessibility-plus-button"
              >
                <img className="accessibility-panel-settings__icon" src={add} alt="add" width="24" height="24" />
              </button>
              <button
                onClick={minusHandler}
                className="accessibility-panel-settings__icon-box accessibility-minus-button"
              >
                <img className="accessibility-panel-settings__icon" src={minus} alt="minus" width="16" height="2" />
              </button>
              <button
                onClick={accessibilityResetButton}
                className="accessibility-panel-settings__icon-box accessibility-reset-button"
              >
                <img className="accessibility-panel-settings__icon" src={backup} alt="backup" width="24" height="24" />
              </button>
            </div>
          </div>
          <div className="accessibility-panel-hide__box">
            <button
              className="accessibility-panel-hide__button-box accessibility-close__btn"
              onClick={turnOnAccessibilityPanel}
            >
              <img className="accessibility-panel-hide__button" src={close} alt="close" width="8" height="8" />
            </button>
            <button
              onClick={turnOnAccessibilityPanel}
              className="accessibility-panel-hide__text-button accessibility-close__btn"
            >
              Сховати налаштування
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccessibilityPanel;
