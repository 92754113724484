import React, { useEffect, useState } from 'react';
import Select from 'react-select';

export interface SelectOption {
  id: string;
  label: string;
}

interface Props<OptionType extends SelectOption = SelectOption> {
  options: OptionType[];
  defaultValue?: object;
  placeholder: string;
  name: string;
  disabled?: boolean;
  className?: string;
  isLoading?: boolean;
  onChange: (newValue: OptionType, name: string) => void;
  onMenuOpen?: () => void;
}

function InitialSelect<OptionType extends SelectOption = SelectOption>({
  options,
  defaultValue,
  placeholder,
  name,
  disabled,
  className,
  onChange,
  onMenuOpen,
}: Props<OptionType>): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectOptions, setSelectOptions] = useState<OptionType[]>([]);

  const handleClick = (): void => {
    if (onMenuOpen) {
      if (!selectOptions.length) {
        setLoading(true);
      }
      onMenuOpen();
    }
  };

  useEffect(() => {
    setSelectOptions(options);
    setLoading(false);
  }, [options]);

  const handleChange = (newValue: OptionType): void => {
    onChange(newValue, name);
  };

  return (
    <Select
      id="select-custom"
      name={name}
      isLoading={loading}
      loadingMessage={(): string => 'Завантаження...'}
      noOptionsMessage={(): string => 'Жодних варіантів'}
      isDisabled={disabled}
      value={defaultValue}
      options={selectOptions}
      placeholder={placeholder}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          height: 40,
          borderRadius: 4,
          border: state.isFocused
            ? '2px solid #1e4dba'
            : className === 'error-input'
              ? '2px solid #d8222f'
              : '2px solid #8d8d8d',
          boxShadow: state.isFocused ? '0 0 3px #1e4dba' : 'none',
          backgroundColor: '#f9f9f9',
          '&:hover': {
            backgroundColor: '#fff',
          },
          ...(state.isDisabled && {
            border: '2px solid #8d8d8d',
            backgroundColor: '#f9f9f9',
            cursor: 'not-allowed',
            filter: 'opacity(0.5)',
          }),
        }),
        option: (styles, { isDisabled, isFocused, isSelected }) => ({
          ...styles,
          backgroundColor: isFocused ? '#f9f9f9' : isSelected ? '#f9f9f9' : 'null',
          color: '#1e4dba',
          fontWeight: 500,
          cursor: isDisabled ? 'not-allowed' : 'pointer',
          borderBottom: '1px solid #e0e0e0',
        }),
        loadingIndicator: (baseStyles) => ({
          ...baseStyles,
          color: '#22429A',
        }),
        valueContainer: (baseStyles) => ({
          ...baseStyles,
          padding: className === 'value-container-padding-0' ? '0px 8px' : baseStyles.padding,
        }),
      }}
      onChange={(newValue): void => handleChange(newValue as OptionType)}
      onMenuOpen={handleClick}
    />
  );
}

export default InitialSelect;
