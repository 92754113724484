import React, { memo, useContext } from 'react';
import './AppealAcceptedAlert.scss';

import { ModalBorderTopColor, ModalConstructor } from '../ModalConstructor';
import ModalContext from '../../../contexts/Modal/ModalContext';
import { Button } from '../../Button';

export const AppealAcceptedAlert = memo(function () {
  const { toggleModal } = useContext(ModalContext);

  return (
    <ModalConstructor modalBorderTopColor={ModalBorderTopColor.yellow}>
      <div className="appeal-accepted-alert">
        <div className="appeal-accepted-alert__title">
          <img src="/images/approved_circle_filled_blue.svg" alt="approved circle filled blue" />
          <div className="block-title_lg">Ваш запит на комунікацію буде розглянутий якнайшвидше</div>
          <span>Супровідник зв’яжеться з вами у термін до 5 робочих днів</span>
        </div>

        <div className="appeal-accepted-alert__buttons-box">
          <Button onClick={(): void => toggleModal(false)}>Добре</Button>
        </div>
      </div>
    </ModalConstructor>
  );
});
