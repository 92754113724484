import { Reason } from '../../contexts/ServiceAvailability/ServiceAvailabilityContext';
import { SelectOption } from '../Forms/Select';

export type DataResponse<D> = {
  data: D;
};

export enum IntegrationSystem {
  ERZ = 'erz',
  IAC = 'iac',
  RMP = 'rmp',
  MSP = 'msp',
}

export enum UserRequestStatus {
  accepted = 'accepted',
  registered = 'registered',
  rejected = 'rejected',
  forwarded = 'forwarded',
  rejection = 'rejection',
  permit = 'permit',
  sent = 'sent',
  finish = 'finished',
  denied = 'denied',
  retained = 'retained',
  fubar = 'fubar',

  ready_to_send = 'ready_to_send',

  payment = 'payment',
  success = 'success',
  draft = 'draft',
}
export enum UserRequestPartStatus {
  permit = 'permit',
  denied = 'denied',
  retained = 'retained',
  impossible = 'impossible',
}

export enum InvoiceStatus {
  IN_PROGRESS = 'in_progress',
  FAILED = 'failed',
  SUCCESS = 'success',
}

export interface Permit<T extends ServiceCodes = ServiceCodes> {
  id: string;
  type: T;
  expiredAt: string;
  issuedAt: string;
  number: string;
  authority: string;
}

export enum ERZObjectMode {
  NORMAL = 'C',
  EXPORT = 'E',
  COMMISSION_SALE = 'S',
  ON_REPAIR = 'R',
}

export interface Weapon {
  weaponType: 'firearm' | 'firearm_part' | 'cold_steel';
  id: string;
  NR: string;
  DV: string;
  DP?: string;
  DO?: string;
  OVD?: string;
  ORSR?: string;
  ORNM: string;
  ORVD: string;
  ORMD: string;
  ORKL: string;
  ORST: string;
  NZ: string;
  ORIZ: string;
  verified: boolean;
  EXTRA?: {
    purchaseOperation?: {
      lastOperationDate: string;
      daysLeft?: string;
      nextOperationDeadline?: string;
    };
    KindId?: string;
    ClassifierId?: string;
    Permit?:
      | [
          Permit<ServiceCodes.STORAGE_CARRYING> | Permit<ServiceCodes.COLD_STEEL_STORAGE_FOR_COLLECTION>,
          Permit<ServiceCodes.TEMPORARY_RESIDENCE_RELOCATION>,
        ]
      | [];
  };
  storageAddress?: string;
  objectMode: ERZObjectMode;
  parts: Array<{
    model: string;
    type: string;
    caliber: string;
    serialNum: string;
  }>;
}

export interface UserData {
  email: string;
  phone: string;
  contact_phone?: string;
  contact_email?: string;
  role: string;
  gender: string;
  status: string;
  created_at: string;
  name_first: string;
  name_last: string;
  name_middle: string;
  date_of_birth: string;
  citizenship?: SelectOption;
  country_birth?: SelectOption;
  region_birth?: SelectOption;
  district_birth?: SelectOption;
  city_birth?: SelectOption;
  country_reg?: SelectOption;
  region_reg?: SelectOption;
  district_reg?: SelectOption;
  city_reg?: SelectOption;
}

export interface IdGovUaData {
  drfo_code: string;
  address_state: string;
  address_city: string;
  address_address: string;
}

export interface SocialProtectData {
  rank: string;
  service_place: string;
  tag: string;
  serve_sign: string;
  out_of_state: string;
}

export interface CriminalWeapon extends Weapon {
  ORNZ?: string;
}

export interface ResponseUserWeapons {
  ipsItems: Weapon[];
  criminalItems: CriminalWeapon[];
}

export enum UserRequestChannel {
  NPU = 'npu',
  EKG = 'ekg',
  SGD = 'sgd',
}

export enum ArtifactType {
  EXTRACT = 'extract',
  RECEIPT = 'receipt',
  SIGN = 'sign',
  MESSAGE = 'message',
  APPLICATION = 'application',
}

export interface UserRequestPart {
  id: string;
  status: UserRequestPartStatus;
  type: string;
  modelName: string;
  artifacts: ArtifactType[];
}

export type UserRequest = {
  id: number;
  title: string;
  status: UserRequestStatus | null;
  service_code: ServiceCodes;
  number_application: string;
  external_decision: string;
  is_archived: boolean;
  is_draft: boolean;

  created_at: string;
  updated_at: string;

  channel: UserRequestChannel;

  meta?: {
    current_step: number;
    last_step_filled: number;
    total_steps: number;
    data: {
      version: string;
      info: string;
    };
  };
  invoice: null | Record<'status', InvoiceStatus>;
  parts: UserRequestPart[] | null;
  artifacts?: ArtifactType[];
};

export interface UserRequestDraft extends UserRequest {
  meta: Required<UserRequest['meta']>;
  stateForm: string | null; // UserRequest.status === UserRequestStatus.payment ? null : string
}

export enum PersonContactKind {
  WORK_PHONE = 'WP',
  HOME_PHONE = 'HP',
  MOBILE_PHONE = 'MP',

  WORK_EMAIL = 'WE',
  PERSONAL_EMAIL = 'PM',

  VIBER = 'VB',
  SIGNAL = 'SG',
  TELEGRAM = 'TG',
  WHATSAPP = 'WA',
}

export enum SocailProtectAppealStatus {
  OPENED = 'O',
  PENDING = 'P',
  CLOSED = 'C',
}

export interface SocailProtectAppeal {
  id: string;
  kind: string;
  type: null;
  created_at: string;
  status: SocailProtectAppealStatus;
  contact_kind: PersonContactKind | null;
  contact_tag: string | null;
  message: string;
}

export type DocumentData = {
  id: number;
  type:
    | DocumentTypeCode.PASSPORT
    | DocumentTypeCode.ID_CARD
    | DocumentTypeCode.INTERNATIONAL_PASSPORT
    | DocumentTypeCode.RESIDENCE
    | DocumentTypeCode.OLD_RESIDENCE;
  fields: {
    type?:
      | DocumentTypeCode.PASSPORT
      | DocumentTypeCode.ID_CARD
      | DocumentTypeCode.INTERNATIONAL_PASSPORT
      | DocumentTypeCode.RESIDENCE
      | DocumentTypeCode.OLD_RESIDENCE;
    typeName?: string;
    series?: string;
    number?: string;
    issue?: string;
    dateIssue?: string;
    expiredDate?: string;
    issueCountryIso2?: string;
  };
};

export type ResponseFindWeapon = DataResponse<Weapon[]>;

export type ResponseUserData = DataResponse<{
  idgovua: IdGovUaData;
  user: UserData;
  documents: DocumentData;
  systems: IntegrationSystem[];
  msp: SocialProtectData;
}>;

export enum FeatureName {
  search = 'weapons.search',
  erzServices = 'erz_services.availability',
  temporaryResidenceRelocation = 'erz_services.temporary_residence_relocation',
  cancellationTemporaryResidenceRelocation = 'erz_services.cancellation_temporary_residence_relocation',
}

export enum ERZServiceLockedReason {
  NO_LINK = 'NO_LINK',
  TIMEOUT_PERMIT = 'TIMEOUT_PERMIT',
  EXPIRED_PERMIT_STORAGE_AND_STORE = 'EXPIRED_PERMIT_STORAGE_AND_STORE',
  EXPIRED_PERMIT_TEMP_RELOCATION = 'EXPIRED_PERMIT_TEMP_RELOCATION',
}

export interface ResponseFeatures {
  [FeatureName.search]: [boolean, null];
  [FeatureName.erzServices]: [boolean, ERZServiceLockedReason | null];
  [FeatureName.temporaryResidenceRelocation]: [boolean, Reason.DUPLICATED_APPLICATION | null];
  [FeatureName.cancellationTemporaryResidenceRelocation]: [boolean, Reason.DUPLICATED_APPLICATION | null];
}

export enum UserServicePaymentType {
  ONLINE = 'online',
  OFFLINE = 'offline',
  BOTH = 'both',
  WITHOUT_PAYMENT = 'without_payment',
}

export interface UserService {
  id: string;
  name: string;
  type: 'online' | 'offline' | 'external';
  source: IntegrationSystem | null;
  category_id: number;
  link: string | null;
  icon: string | null;
  code: ServiceCodes | null;
  withAuth: boolean;
  isOnboarding: boolean;
  documentName: string | null;
  video_instruction?: string;

  isAvailable: boolean;
  paymentType: UserServicePaymentType;
}

export enum ServiceCodes {
  // ERZ
  RESIDENCE_RELOCATION = 'IAC01',
  TEMPORARY_RESIDENCE_RELOCATION = 'IAC02',
  CANCELLATION_TEMPORARY_RESIDENCE_RELOCATION = 'IAC03',
  PURCHASE = 'IBB01',
  COLD_STEEL_STORAGE_FOR_COLLECTION = 'ICS01',
  STORAGE_CARRYING = 'ISC01',
  TRANSPORT_THROUGH_UKRAINE = 'ITT01',
  IMPORT_IN_UKRAINE = 'ITT02',
  TRANSPORT_ACROSS_BORDER = 'ITT05',
  COLD_STEEL_TRANSPORTATION = 'ITT06',
  COMMISSION_SALE = 'IVE01',

  CRIMINAL_RECORD_EXTRACT = 'CEAA01',
  CRIMINAL_RECORD_SEARCH = 'CEAA02',
  CRIMINAL_RECORD_SEARCH_ALL = 'CEAA03',

  VALIDITY_PERIOD_PROLONGATION = 'extension', // TODO: change (refactor)
  ERZ_EXTRACT = 'erz.extract',
  ERZ_SEARCH = 'erz.search',

  // OTHER
  MISSING_PERSON = 'missingPerson',
  SOCIAL_PROTECT = 'social_protect',
}

export interface ERZPermit {
  id: string;
  permitType: ServiceCodes;
  num: string;
  issuedAt: string;
  expiredAt: string;
  canExtract: boolean;
  objects: Array<{
    id: string;
    type: string | null;
  }>;
}

export interface DictionaryPermitType {
  name: string;
  label: string;
}

export type ServiceCategory = {
  id: string;
  name: string;
  slug: string;
  description: string;
};

export type IACDocumentTypeCode =
  | DocumentTypeCode.PASSPORT
  | DocumentTypeCode.ID_CARD
  | DocumentTypeCode.INTERNATIONAL_PASSPORT
  | DocumentTypeCode.RESIDENCE;

export enum DocumentTypeCode {
  LEASE_AGREEMENT = 'lease_agreement',
  OWNERSHIP_CERTIFICATE = 'ownership_certificate',
  VITYAG_TERITORIALNOYI_GROMADI = 'address',
  LEGAL_RESIDENCE = 'legal_residence',
  CERT_IDP = 'cert_idp',
  CERT_MARRIAGE = 'cert_marriage',
  EXRACT_PRPR = 'exract_prpr',
  PAYMENT_REFERENCE = 'payment_reference',
  MEDICAL_REFERENCE = 'medical_reference',
  INSURANCE_POLICY = 'insurance_policy',
  WORK_REFERENCE = 'work_reference',
  TECHNICAL_REFERENCE = 'technical_reference',
  POWER_OF_ATTORNEY = 'power_of_attorney',
  TECHNICAL_CONDITION = 'technical_condition',
  INVITE = 'invite',
  INVITE_UKR = 'invite_ukr',
  RESOLUTION = 'resolution',
  PERMIT = 'permit',
  CERTIFICATION = 'certification',
  RNOKPP = 'rnokpp',
  PHOTO_CARD = 'photo_card',
  MISSING_PERSON_PHOTO_CARD = 'missing_person_photo_card',
  PASSPORT = 'passport',
  ID_CARD = 'id_card',
  INTERNATIONAL_PASSPORT = 'international_passport',
  RESIDENCE = 'residency',
  OLD_RESIDENCE = 'old_residency',
  ADDITIONAL_DOCUMENT = 'additional_document',
  DISAPPEARANCE_MISSING_PERSON = 'disappearance_missing_person',
  EXTRACT_ERDR = 'extract_erdr',
  ANCESTRAL_CONNECTION = 'ancestral_connection',
  WEAPON_LEFT_PHOTO = 'weapon_left_photo',
  WEAPON_RIGHT_PHOTO = 'weapon_right_photo',
  EXPERT_RESEARCH = 'expert_research',

  COMMISSION_SALE = 'application_IVE01',
  PURCHASE = 'application_IBB01',
  TRANSPORT_THROUGH_UKRAINE = 'application_ITT01',
  IMPORT_IN_UKRAINE = 'application_ITT02',
  TRANSPORT_ACROSS_BORDER = 'application_ITT05',
  COLD_STEEL_TRANSPORTATION = 'application_ITT06',
  STORAGE_CARRYING = 'application_ISC01',
  COLD_STEEL_STORAGE_FOR_COLLECTION = 'application_ICS01',
  RESIDENCE_RELOCATION = 'application_IAC01',
  TEMPORARY_RESIDENCE_RELOCATION = 'application_IAC02',
  VALIDITY_PERIOD_PROLONGATION = 'application_extension',
  MISSING_PERSON = 'application_missingPerson',
}

export interface DocumentType<System extends IntegrationSystem = never> {
  id: string;
  kind: System extends IntegrationSystem.ERZ ? 'A' | 'B' | 'C' | 'D' | 'E' | 'F' : never;
  name: string;
  code: System extends IntegrationSystem.ERZ
    ? DocumentTypeCode
    : System extends IntegrationSystem.IAC
      ? IACDocumentTypeCode
      : never;
}

export type FormDataType = Record<string, string | number | File[] | string[] | boolean | Date>;

export enum UserAreaRoutes {
  dashboardDefault = '/dashboard',
  dashboardActiveOrders = '/dashboard/active-requests',
  dashboardArchivedOrders = '/dashboard/archived-requests',
  dashboardDraftOrders = '/dashboard/draft-requests',
  profile = '/profile',
  commissionSales = '/commission-sales',
  collectableWeaponsTransportation = '/collectable-cold-weapons-transportation',
  weaponRegistered = '/weapon-registered',
  weaponPurchasePermit = '/weapon-purchase-permit',
  residenceRelocation = '/residence-relocation',
  temporaryResidenceRelocation = '/temporary-residence-relocation',
  erzExtract = '/erz-extract',
  weaponTransportation = '/weapon-transportation',
  weaponStorage = '/weapon-storage-carrying',
  permissionRenewal = '/permission-renewal',
  missingPerson = '/missing-person',
  coldSteelStorageForCollection = '/cold-steel-storage-for-collection',
  criminalRecordExtract = '/criminal-record-extract',
  criminalRecordSearch = '/criminal-record-search',
  criminalRecordSearchAll = '/criminal-record-search-all',
  socialProtect = '/social-protect',
  socialProtectNeeds = '/social-protect/needs',
  socialProtectMentor = '/social-protect/mentor',
  socialProtectService = '/social-protect/service',
  socialProtectTreatment = '/social-protect/treatment',
  socialProtectSupport = '/social-protect/support',
  cancellationTemporaryResidenceRelocation = '/cancellation-temporary-residence-relocation',
}

export interface PurchaseItem {
  id: PurchaseItemIdMapping;
  name: string;
}

export enum PurchaseItemIdMapping {
  BLANK_WEAPON = 'dbaebc49-5e1a-4454-8288-0942de17acbb',
  GAS_REVOLVERS = 'f95a799e-4bd4-4db8-ad21-8e15a4e53bc8',
  HUNTING_SMOOTHBORE_WEAPONS = '2c3886ec-bfa1-4c15-b4b3-2be63e94301e',
  PNEUMATIC_WEAPONS = '3fc24a98-df37-4fdc-8ea3-df4195ed1da9',
  COLD_WEAPON = '5b0846a3-47e9-4248-87c9-56ad5c90e679',
  WEAPONS_MAIN_PART = 'd6c1dd07-0913-44f1-af52-ee54b5333b48',
  HUNTING_RIFLED_WEAPONS = '57a35547-da40-4596-82ab-747cb6f0b8a7',
  DEVICE = 'ad898d08-eb2e-48d4-9712-bb8f4638314a',
}

export type PayloadVerifySubmitAvailability = Record<'last_name' | 'first_name' | 'patronymic' | 'birthday', string>;

export interface ResponseVerifySubmitAvailability {
  canSubmit: boolean;
}

export interface PayloadCreateDraft {
  id: string;
  name: string;
  code: ServiceCodes;
}

export interface ResponseCreateDraft {
  requestId: number;
}

export interface PayloadUpdateDraft {
  draftId: number;
  serviceDraft: Pick<UserRequestDraft, 'meta'> & {
    stateForm: FormDataType;
  };
}

interface GeneralDictionaryType {
  id: string;
  name: string;
}

export type DictionaryColdSteelFactory = GeneralDictionaryType;
export type DictionaryRegion = GeneralDictionaryType;
export type DictionaryDistrict = GeneralDictionaryType;
export type DictionaryCity = GeneralDictionaryType;
export type DictionaryIndividualStatus = GeneralDictionaryType;
export type DictionaryProposal = GeneralDictionaryType;
export interface DictionaryColdSteelType extends GeneralDictionaryType {
  kind: 1 | 2 | 3 | 4;
}
export interface DictionaryStreet extends GeneralDictionaryType {
  subType: { abbr: string };
}
export interface DictionaryAuthority extends GeneralDictionaryType {
  code: string;
}
export interface DictionaryCountry {
  id: string;
  shortName: string;
  numb: string;
  codeAlpha2: string;
  codeAlpha3: string;
}
export interface DictionaryWeaponCategory extends GeneralDictionaryType {
  code: 'D' | 'E';
}

// Taken from file "links.json"
export interface ResponseStoredLinks {
  serviceInformer: Record<
    | 'policeAdresses'
    | 'paymentDetails'
    | 'free'
    | 'policeDutyUnits'
    | 'vityagTeritorialnoyiGromadi'
    | 'missingPersonDetails',
    string
  >;
  externalLink: Record<'npuKontakti' | 'zakon622' | 'zakon379' | 'zakon223' | 'mvsWebsite', string>;
  videoInstruction: {
    profile: string | null;
    myWeapons: string | null;
    authorization: string | null;
    dashboard: {
      activeRequest: string | null;
      archive: string | null;
      draft: string | null;
    };
  };
}

export type ServiceSupportedCustomWeaponList =
  | ServiceCodes.RESIDENCE_RELOCATION
  | ServiceCodes.TEMPORARY_RESIDENCE_RELOCATION
  | ServiceCodes.STORAGE_CARRYING
  | ServiceCodes.COMMISSION_SALE
  | ServiceCodes.COLD_STEEL_STORAGE_FOR_COLLECTION
  | ServiceCodes.COLD_STEEL_TRANSPORTATION
  | ServiceCodes.TRANSPORT_ACROSS_BORDER
  | ServiceCodes.PURCHASE;

export type AccordionsTextKeys =
  | ServiceCodes.COLD_STEEL_STORAGE_FOR_COLLECTION
  | ServiceCodes.COLD_STEEL_TRANSPORTATION
  | ServiceCodes.COMMISSION_SALE
  | ServiceCodes.VALIDITY_PERIOD_PROLONGATION
  | ServiceCodes.RESIDENCE_RELOCATION
  | ServiceCodes.PURCHASE
  | ServiceCodes.STORAGE_CARRYING
  | ServiceCodes.TRANSPORT_ACROSS_BORDER
  | ServiceCodes.TEMPORARY_RESIDENCE_RELOCATION;

export type AccordionsTextContent = {
  title: string;
  content: string;
};

export type ResponseAccordionText = Record<AccordionsTextKeys, AccordionsTextContent[]>;

export enum ERZExtractKind {
  // For natural person
  WEAPON_PURCHASE = 'E004',
  STORAGE_CARRYING_WEAPON = 'E005',
  STORAGE_CARRYING_PRIZE_WEAPON = 'EI04',
  STORAGE_CARRYING_COLD_STEEL = 'EI05',
  WEAPON_RE_REGISTRATION = 'E013',
  ALL_EXISTING_PERMITS = 'R005',

  // Both: for juridical and natural person
  IMPORT_AND_EXPORT_WEAPON = 'E008',

  // For juridical person
  OPEN_AND_OPERATE_WITH_EXPLOSIVE_MATERIALS = 'E001',
  EXPLOSIVE_MATERIALS_PURCHASE = 'E002',
  EXPLOSIVE_MATERIALS_TRANSPORTATION = 'E003',
  DEPARTMENT_WEAPONS_CARRYING = 'E007',
  IMPORTED_ITEMS_USAGE = 'E009',
  DEPARTMENT_WEAPONS_RE_REGISTRATION = 'E012',
  COMMISSION_SALE = 'E014',
}

export type RMPSearchRequestType = 'qr' | 'simple';

export type RMPSearchQueryParams = {
  type: RMPSearchRequestType;
  numb?: string;
  birthDate?: string;
  data?: string;
  path?: string;
  requestId?: string;
  verifyCode?: string;
};

export type ErzSearchQueryParams = {
  numb: string;
  secret: string;
};

export type ResponseExtract<T extends IntegrationSystem> = DataResponse<
  Record<'kind', T extends IntegrationSystem.ERZ ? ERZExtractKind : null> & DataResponse<Array<[string, string]>>
>;

export enum PaymentDetailsFieldName {
  price = 'price',
  fee = 'fee',
  receiver = 'receiver',
  edrpou = 'edrpou',
  iban = 'iban',
  purpose = 'purpose',
}

export type ResponsePaymentDetails = {
  [PaymentDetailsFieldName.price]: number;
  [PaymentDetailsFieldName.fee]: number;
  [PaymentDetailsFieldName.receiver]: string;
  [PaymentDetailsFieldName.edrpou]: string;
  [PaymentDetailsFieldName.iban]: string;
  [PaymentDetailsFieldName.purpose]: string;
};

export interface SocialProtectNeedDetails {
  id: string;
  kind: string;
  name: string;
  from: string;
  till: string;
  state: string;
}

export interface SocialProtectMentorDetails {
  name: string;
  assign: string;
  phone: string;
}

export interface SocialProtectTreatmentDetails {
  name: string;
  from: string;
  till: string;
  card: string;
}

export interface SocialProtectServiceDetails {
  place: string;
  from: string;
  till: string;
}

export interface SocialProtectSupportDetails {
  type: string;
  mode: string;
  when: string;
  duration: string;
  psychologist: string;
}

export type ResponseSocialProtectDetails = {
  needs: SocialProtectNeedDetails[];
  mentor: SocialProtectMentorDetails[];
  treatment: SocialProtectTreatmentDetails[];
  service: SocialProtectServiceDetails[];
  support: SocialProtectSupportDetails[];
};
