import React, { MouseEvent, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import './DesktopMainMenu.scss';

import logo from '../../../assets/images/logo.svg';
import AuthContext from '../../../contexts/Auth/AuthContext';
import { GovUaToastContent } from '../../Toasts/GovUaToast';
import { OrderServiceLink } from '../Header/OrderServiceLink';
import { Drawer } from '../Drawer/Drawer';

const DesktopMainMenu = (): JSX.Element => {
  const location = useLocation();
  const authContext = useContext(AuthContext);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isGovUaToastShown, setGovUaToastShown] = useState(false);

  const govUaToastOnClick = (e: MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    setGovUaToastShown(!isGovUaToastShown);
  };

  let pageContent;

  switch (location.pathname) {
    case '/':
      pageContent = <HomePage />;
      break;
    default:
      pageContent = <AllPage />;
  }

  function toggleDrawer(): void {
    setDrawerOpen(!isDrawerOpen);
  }

  function HomePage(): JSX.Element {
    return AllPage();
  }

  function AllPage(): JSX.Element {
    return (
      <>
        <div className="desktop-main-menu__menu-button">
          <button onClick={(): void => toggleDrawer()}>
            <MenuOutlined style={{ color: 'white' }} />
          </button>
        </div>
      </>
    );
  }

  return (
    <div className="desktop-main-menu" id="main-menu">
      <Drawer
        isDrawerOpen={isDrawerOpen}
        toggleDrawer={(): void => toggleDrawer()}
        govUaToastOnClick={govUaToastOnClick}
        logoutHandler={(): void => {
          toggleDrawer();
          authContext.logout();
        }}
      />
      <Modal
        zIndex={10001}
        className="desktop-gov-id-dialog"
        open={isGovUaToastShown}
        onCancel={govUaToastOnClick}
        footer={null}
        closable={false}
      >
        <GovUaToastContent govUaToastHandler={govUaToastOnClick} />
      </Modal>
      <div className="container desktop-main-menu__container">
        <span className="desktop-main-menu__left-data" />
        <a href="/" className="desktop-main-menu__logo">
          <img className="desktop-main-menu__logo" src={logo} alt="logo" width="72" height="72" />
        </a>
        {pageContent}
      </div>

      <div className="desktop-main-menu__additional-row">
        <div className="container desktop-main-menu__additional-row__container">
          <OrderServiceLink />
        </div>
      </div>
    </div>
  );
};

export default DesktopMainMenu;
